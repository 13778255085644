@layer reset, base, tokens, recipes, utilities;

@import './reset.css';

@import './global.css';

@import './tokens/index.css';

@import './tokens/keyframes.css';

@layer utilities {
  .bXxaRC {
    transition: border-color 200ms linear
    }

  .dSqbha[data-state="open"] {
    border-color: var(--jRGBpu)
        }

  .jsEQnN {
    padding-left: 41px
    }

  .fzVImw[data-state="open"] {
    padding-block: 20px
        }

  .diFkis {
    list-style-type: none
    }

  .fNDlyX {
    padding-inline-start: 0px
    }

  .jsgMqw {
    padding-inline-start: 16px
    }

  .iYrRwC > li {
    list-style-type: disc
        }

  .cKrkvC:last-child {
    margin-bottom: 0px
        }

  .hGErDk {
    line-height: 22px
    }

  .ATIAN {
    margin: 0px
    }

  .iehryL[data-state="open"] > [data-trigger-chevron] {
    transform: rotate(180deg)
        }

  .ATIAI {
    height: 0px
    }

  .caVGIn {
    border-left-width: 6px
    }

  .kksjYp {
    border-left-style: solid
    }

  .eRHdvi {
    border-left-color: var(--lcYDiE)
    }

  .jOWiiU {
    border-right-width: 6px
    }

  .jPMnIQ {
    border-right-color: var(--lcYDiE)
    }

  .hyIhzN {
    border-top-width: 8px
    }

  .iBzonw {
    border-top-color: var(--jRGBpu)
    }

  .XhAWi {
    transition: transform 200ms linear
    }

  .pAKQs {
    width: 700px
    }

  .gXtYUu {
    transition: background 200ms ease-in-out
    }

  .fiwvvC {
    padding: 32px 40px
    }

  .ekBWEb {
    line-height: 25px
    }

  .bGGLR[data-state=open] {
    padding-bottom: 0px
        }

  .kHvvwy[data-state=open] {
    border-bottom-color: transparent
        }

  .gOmCrS {
    padding: 16px 40px 32px
    }

  .cqxQtf[data-state=open] {
    animation: slideDown 200ms ease-in-out
        }

  .jajVHp[data-state=closed] {
    animation: slideUp 100ms ease-in-out
        }

  [data-state=open] .kFAFBO {
    animation: fadeIn 1s ease-in-out
        }

  [data-state=closed] .hQzHfy {
    animation: fadeOut 10ms ease-out
        }

  .fznWHu {
    width: 36px
    }

  .fznWHJ {
    height: 36px
    }

  .kHKezn {
    z-index: 9998
    }

  .fSEqP {
    background: rgba(109, 104, 173, 0.4)
    }

  .iEgNmr {
    animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1)
    }

  .dDihBf {
    top: 50%
    }

  .eCaVMz {
    left: 50%
    }

  .kNbvZu {
    z-index: 9999
    }

  .fmhMWf {
    width: 90vw
    }

  .eeoBBd {
    max-width: 32rem
    }

  .jJQbUA {
    transform: translate(-50%, -50%)
    }

  .dXWnhE {
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)
    }

  .jrinsC {
    margin-bottom: 18px
    }

  .jrinjk {
    font-size: 18px
    }

  .eaLlZN {
    top: 1.25rem
    }

  .kDskCO {
    right: 1.25rem
    }

  .gjmaOV[type="button"] {
    background-color: $purple500
        }

  .kgpGcM {
    background-color: $yellow100
    }

  .ioAlIT {
    border-color: $yellow300
    }

  .jOCng {
    fill: $orange300
    }

  .gpdioi {
    color: $orange300
    }

  .lmtHMj {
    fill: #9B90D1
    }

  .leOAdu {
    border: 1px solid $red500
    }

  .ksUhqp svg {
    color: $purple500
        }

  .cuyDGw svg {
    position: absolute
        }

  .kGVHhk svg {
    top: 0
        }

  .nJWmD svg {
    right: 0
        }

  .hSQYlm[data-audio-animation-recording] + div {
    display: none
        }

  .iaLocz {
    transform-origin: top left
    }

  .dSXzFH {
    height: 5rem
    }

  .hePYtC {
    text-align: center
    }

  .gjGIhk {
    width: auto
    }

  .hRVjPV {
    margin: 0
    }

  .faltTG {
    font-size: 16px
    }

  .cTojXO {
    line-height: 24px
    }

  .dLgCbE {
    color: $neutral0
    }

  .INLUu {
    font-weight: $bold
    }

  .kabXGs {
    border-radius: 10rem
    }

  .bPqIsa {
    order: -1
    }

  .cnBqms {
    transform: scale(1.2)
    }

  .gRIgdj {
    transform: scale(1.5)
    }

  .eDFFfx > svg {
    width: 50%
        }

  .EXeYS > svg {
    height: 50%
        }

  .eqXfZM > svg {
    fill: $neutral0
        }

  .btkxWq {
    background: $purple500
    }

  .kndkSn {
    background: $red500
    }

  .laipGL {
    background: $green500
    }

  .eIzNqi {
    background: $orange300
    }

  .cXjUyC {
    border-radius: inherit
    }

  .fzoyvf {
    width: 40px
    }

  .fzoyvs {
    height: 40px
    }

  .fznzDB {
    width: 64px
    }

  .fznzDC {
    height: 64px
    }

  .oTBuA {
    width: 124px
    }

  .oTBuf {
    height: 124px
    }

  .jDXpfD {
    background: green500
    }

  .jOreYx {
    background: neutral500
    }

  .hWUsre {
    gap: $base05
    }

  .ftMziC {
    fill: $neutral0
    }

  .eIzNqi {
    background-color: $orange300
    }

  .gButzN {
    text-indent: -9999px
    }

  .fAlIzV::before,.fOppdY::after {
    background-color: $purple500
        }

  .jkycxq a {
    color: $purple500
        }

  .jeUSVV a {
    text-decoration: none
        }

  .cMCMUS path {
    stroke: $neutral300
        }

  .jBPRew {
    fill: #999999
    }

  .jBNQvG {
    color: #999999
    }

  .cJbwbJ:checked,.eCfLGT:checked:disabled {
    border-color: transparent
        }

  .bGTgkg {
    appearance: none;
    -webkit-appearance: none
    }

  .TtbQC:disabled {
    opacity: 0.9
        }

  .dWkIxr:disabled {
    border-color: rgba(0, 0, 0, 0.1)
        }

  .iEMRWF:disabled {
    background-color: $neutral100
        }

  .sqYUi:checked::before {
    transform: scale(1)
        }

  .gEefTi::before {
    transform: scale(0)
        }

  .eCBywU::before {
    transform-origin: bottom left
        }

  .iFHbBf::before {
    clip-path: polygon(6.25% 54%, 0% 64.5%, 36.25% 100%, 100% 7.5%, 89.75% 0%, 36.25% 82.75%);
    -webkit-clip-path: polygon(6.25% 54%, 0% 64.5%, 36.25% 100%, 100% 7.5%, 89.75% 0%, 36.25% 82.75%)
        }

  .gPYSXk {
    overflow-x: scroll
    }

  .fzrjPj {
    margin-bottom: 8px
    }

  .goYVUU {
    background: pink500
    }

  .ggeyjl {
    max-width: 1200px
    }

  .lmnfPW {
    border: solid 1px transparent
    }

  .IGfGY {
    padding: 4px 12px
    }

  .jsxFtA {
    font-size: 10px
    }

  .cfNaWv {
    border-radius: 40px
    }

  .pAMKV {
    width: 200px
    }

  .gjdaJf {
    max-width: 692px
    }

  .jRkGDK {
    min-height: 500px
    }

  .dDclqi {
    max-height: 85vh
    }

  .dpsnfn {
    gap: 14px
    }

  .dQdung {
    flex-direction: row-reverse
    }

  .dpOZsJ {
    top: 64px
    }

  .fznyzd {
    height: 14px
    }

  .egBtNs {
    max-width: 44rem
    }

  .jvINEb > [data-alert-close-button] {
    top: 3rem
        }

  .WVDLh > [data-alert-close-button] {
    right: 1.5rem
        }

  .jFzHwW\! {
    overflow: initial !important
    }

  .fznYYn {
    height: 16px
    }

  .pBzuF {
    width: 130px
    }

  .fzoYUC {
    height: 42px
    }

  .jFXZZd {
    grid-area: allButton
    }

  .duXFU {
    grid-area: createButton
    }

  .dLFwsM {
    grid-template-rows: repeat(2, auto)
    }

  .jUClKP {
    grid-template-columns: 1fr auto
    }

  .iJMNyo {
    grid-template-areas: "title allButton" "createButton createButton"
    }

  .jwdkhP {
    height: 17.5rem
    }

  .jweaiI\! {
    padding-inline: 1.5rem !important
    }

  .dnRxZk\! {
    padding-top: 2rem !important
    }

  .bZcpsG\! {
    padding-bottom: 5.375rem !important
    }

  .cOEFXT {
    transform: rotate(180deg)
    }

  .jfyotZ {
    transition: transform 300ms ease-in-out
    }

  .pCmHl {
    gap: 3px
    }

  .fnABLz {
    max-width: 65rem
    }

  .Jkfjz {
    border-bottom: 1px solid $neutral100
    }

  .gIevkm {
    grid-template-columns: repeat(2, auto)
    }

  .hCtjTF {
    grid-template-areas: "createdAt status" "updatedAt updatedAt" "completedAt completedAt";
    }

  .jSqJiD {
    gap: 10px
    }

  .pboae {
    margin-top: 25px
    }

  .dDCdKx {
    top: 97px
    }

  .cEnCrd {
    height: calc(100vh - (64px))
    }

  .pANgP {
    z-index: 100
    }

  .jsLXSB {
    width: 482px
    }

  .dQdiHI {
    left: 1.2rem
    }

  .jiRIxC {
    top: 100px
    }

  .hMuRWI {
    height: calc(100vh - (64px + 24px))
    }

  .pIJVu {
    z-index: 101
    }

  .jxVYdk {
    border-radius: 12px 0 0 12px
    }

  .efnIHa {
    padding: 26px 36px
    }

  .gyuMSE {
    box-shadow: 0 8.86364px 26.5909px rgba(0, 0, 0, 0.1)
    }

  .HOtca {
    left: unset
    }

  .fvQRHi {
    bottom: unset
    }

  .mFWoX {
    width: 98%
    }

  .jnHgca {
    top: unset
    }

  .ekGtQq {
    height: 40vh
    }

  .gJZtqw {
    border-radius: 32px 32px 0 0
    }

  .gfRcAX {
    top: 2rem
    }

  .cwDkoA {
    right: 2rem
    }

  .bGUtFp::before {
    height: 2px
        }

  .bwYmlL::before {
    right: 0px
        }

  .bsGnuu::before {
    background: var(--fcfsVb)
        }

  .fKLaDd::before {
    rotate: 45deg
        }

  .hSdnZk::after {
    height: 2px
        }

  .fpbMix::after {
    top: 0px
        }

  .ldLmij::after {
    background: var(--fcfsVb)
        }

  .pIuQp::after {
    rotate: -45deg
        }

  .iTKAJA,.hnlvPQ {
    fill: $neutral200
    }

  .gVJELl {
    fill: $neutral300
    }

  .cEowv {
    max-width: 30rem
    }

  .fyGZOs {
    max-width: 48rem
    }

  .iLmzqZ {
    left: 20px
    }

  .eFuPCA::before {
    width: 100%
        }

  .hleXmR::before {
    height: 2px
        }

  .gunYBM::before {
    top: 0
        }

  .eGNlko::before {
    bottom: 0
        }

  .juRpOI::before {
    left: 0
        }

  .cgkyJT::before {
    right: 0
        }

  .csNLjx::before {
    margin: auto
        }

  .hLkMWD::before {
    background-color: $neutral0
        }

  .hLJWdR::before {
    rotate: 45deg
        }

  .gcidDt::after {
    width: 100%
        }

  .bprDvI::after {
    height: 2px
        }

  .dCrjkN::after {
    top: 0
        }

  .bpuMrN::after {
    bottom: 0
        }

  .lhNBpt::after {
    left: 0
        }

  .eKJNom::after {
    right: 0
        }

  .dvwpdA::after {
    margin: auto
        }

  .hbrOWG::after {
    background-color: $neutral0
        }

  .htrdcF::after {
    rotate: -45deg
        }

  .iFdwoo\! {
    font-family: $dm-sans !important
    }

  .fSEqP {
    background-color: rgba(109, 104, 173, 0.4)
    }

  .izjGEz {
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px)
    }

  .hWgZTB {
    inset: 0
    }

  .gQXDWc {
    fill: $neutral0
    }

  .oTrYT {
    margin-top: 24px
    }

  .jrWfdo {
    width: 395px
    }

  .fyplMZ {
    cursor: default
    }

  .kczmeJ[aria-checked=true]::before {
    border-color: var(--jdlUVz)
            }

  .kEOEgI[aria-checked=true]::before {
    background: var(--jdlUVz)
            }

  .ghEwYH[aria-checked=true]::before {
    color: var(--lcYDiE)
            }

  .cOkyZZ[aria-checked=true]::after {
    display: block
            }

  .jhQoxA:disabled {
    background: var(--lbfZji)
        }

  .fuJFTK:disabled {
    opacity: 0.5
        }

  .fUjFCy[aria-checked=true]:disabled::before, .fUjFCy[aria-checked=false]:disabled::before {
    border-color: var(--lbfZji)
            }

  .evAqKa[aria-checked=true]:disabled::before, .evAqKa[aria-checked=false]:disabled::before {
    background: var(--fcfsVb)
            }

  .oeUsI::before {
    display: flex
        }

  .fGpCfW::before {
    align-items: center
        }

  .GxLUu::before {
    justify-content: center
        }

  .jBUKtl::before {
    font-size: 12px
        }

  .hOzwYE::before {
    border-radius: 4px
        }

  .etjGDt::before {
    background-color: var(--lcYDiE)
        }

  .jXvkxE::after {
    display: none
        }

  .csBFiM::after {
    left: 23px
        }

  .bqyyKB::after {
    margin-top: -3px
        }

  .leaBMe::after {
    width: 7px
        }

  .clUVpP::after {
    height: 10px
        }

  .iJpTlo::after {
    rotate: 45deg
        }

  .iACrVF::after {
    border-bottom-width: 2px
        }

  .ebemBr::after {
    border-bottom-style: solid
        }

  .hqDHsu::after {
    border-bottom-color: var(--lcYDiE)
        }

  .dinNva::after {
    border-right-width: 2px
        }

  .ftRnvU::after {
    border-right-style: solid
        }

  .iTwaku::after {
    border-right-color: var(--lcYDiE)
        }

  .eoGVWi {
    line-height: 24px
    }

  .dvMkiN {
    text-align: right
    }

  .kMuYnj {
    fill: $purple200
    }

  .cWdJFQ {
    margin-bottom: 1.125rem
    }

  .ekDfGw {
    font-size: 1.125
    }

  .cjzTKx > a {
    color: var(--jRGBpu)
        }

  .RxMEX > a {
    font-weight: var(--ghWdwL)
        }

  .fHCUBA > a {
    text-decoration: underline
        }

  .dkASff {
    width: 10.625rem
    }

  .dkASfs {
    height: 10.625rem
    }

  .bnttUo a {
    color: var(--jRGBpu)
        }

  .pDxJF {
    margin-top: 80px
    }

  .pALGi {
    width: 500px
    }

  .fzoxFp {
    width: 60
    }

  .fzoxFy {
    height: 60
    }

  .kULCND {
    border: 3px solid $purple300
    }

  .gWbZxp {
    max-width: fit-content
    }

  .kxcpce[disabled] {
    cursor: not-allowed
        }

  .ipetvk {
    padding: 16px 16px 16px 48px
    }

  .cmFoOo[aria-invalid=true],.iOFUlr[aria-invalid=true]::before {
    border-color: var(--iYfRb)
            }

  .CQMyf[aria-checked=true] {
    border-color: var(--jRGBpu)
        }

  .isPMsS[aria-checked=true] {
    color: var(--jRGBpu)
        }

  .eCNPLX[aria-checked=true]::before {
    border-width: 5px
            }

  .kXhndO[aria-checked=true]::before {
    border-style: solid
            }

  .jsNvuM[aria-checked=true]::before {
    border-color: var(--jRGBpu)
            }

  .gZNdhU::before {
    top: 0px
        }

  .cCrgRY::before {
    bottom: 0px
        }

  .bDwOQL::before {
    left: 16px
        }

  .beBJyu::before {
    width: 20px
        }

  .krTdTh::before {
    height: 20px
        }

  .etjGDt::before {
    background: var(--lcYDiE)
        }

  .dLzNJQ::before {
    border-radius: 40px
        }

  .ioOpzd::before {
    border-width: 1px
        }

  .fivhXo::before {
    border-style: solid
        }

  .cIQAuT::before {
    border-color: var(--fcfsVb)
        }

  .bMpqsa {
    opacity: 0.5
    }

  .lgBfkU {
    line-height: 1.5rem
    }

  .fNCnEx {
    margin-top: 4px
    }

  .jsVUaf {
    font-size: 13px
    }

  .eYHxsY {
    padding: 8px 16px
    }

  .heJQVK[data-highlighted="true"],.ljafPj:hover {
    background: #F1F1F1
        }

  .gYrXBK[open]:not(aria-disabled="true")>summary {
    outline: 1px solid $purple500
            }

  .fOrYGZ[open]:not(aria-disabled="true")>summary {
    border-color: transparent
            }

  .crohil[open]:not(aria-disabled="true")>summary {
    color: $purple500
            }

  .eQGfyR {
    width: 6px
    }

  .gYisml {
    background: transparent
    }

  .dPqORi {
    background: rgba(63, 67, 80, 0.24)
    }

  .gteQec {
    height: 83px
    }

  .eMQTlk {
    border-radius: 40px
    }

  .cHmnMO {
    border: 1px solid $neutral300
    }

  .lgICmR[aria-disabled="true"] {
    background-color: #f0f0f0
        }

  .iBpCqG[aria-disabled="true"] {
    border-color: #DDD
        }

  .jBWHRG[aria-disabled="true"] {
    color: #999
        }

  .jJSrYY[aria-disabled="true"] {
    cursor: default
        }

  .fWnIDX[aria-expanded="true"] {
    outline: 1px solid $purple500
        }

  .ekqFkE[aria-expanded="true"] {
    border-color: transparent
        }

  .jDFVAK[aria-expanded="true"][data-placeholder] {
    color: $purple500
            }

  .loznr[aria-expanded="true"]>div {
    rotate: 180deg
            }

  .pJwDJ[data-placeholder] {
    color: $neutral300
        }

  .jnbhMv {
    -webkit-line-clamp: 1
    }

  .AxjtX {
    width: 0
    }

  .Axjtw {
    height: 0
    }

  .ibhDiG {
    border-color: $purple500 transparent transparent transparent
    }

  .qbeer {
    top: 0
    }

  .eEkrue[aria-disabled="true"] {
    border-top-color: #999
        }

  .cCQxfN {
    max-height: 246px
    }

  .kkAQda {
    overflow: hidden auto
    }

  .bCShOO {
    background-color: white
    }

  .fOrBUQ {
    border: 1px solid $neutral100
    }

  .Axhzr {
    z-index: 5
    }

  .dxMwDr svg {
    fill: white
        }

  .jwcOcr {
    padding-bottom: 0.5rem
    }

  .eGizhS {
    border-radius: 0.5rem
    }

  .bUHRjE {
    line-height: 1.313rem
    }

  .ioQzzb {
    margin-top: 0.75rem
    }

  .gWcfgT\! {
    min-width: fit-content !important
    }

  .gWbZxp\! {
    max-width: fit-content !important
    }

  .gfxVaX\! {
    height: 2.5rem !important
    }

  .hryFmx {
    background-color: var(--iYfRb)
    }

  .hYxPWP {
    width: 1.625rem
    }

  .hYxPWw {
    height: 1.625rem
    }

  .cGgTkT {
    color: $red500
    }

  .eiCzOS::placeholder {
    color: $neutral300
        }

  .gKedlK {
    border-color: transparent
    }

  .jJVYMs {
    max-width: 26rem
    }

  .kndkSn {
    background-color: $red500
    }

  .hjftcO {
    border-radius: 100px
    }

  .fUfBYX {
    align-items: flex-end
    }

  .ccXsCl {
    border-bottom: 5px solid $red500
    }

  .khVTad:last-of-type {
    border-top: 1px solid $neutral200
        }

  .gaSkLs button[data-state=open] {
    border-bottom-color: $neutral200
            }

  .YeBg,.VWUxX {
    background-color: #F7F7F8
    }

  .mEalf {
    width: 90%
    }

  .dQqBxN {
    color: $purple500
    }

  .keVHda {
    min-height: unset
    }

  .hemyeN {
    fill: $neutral300
    }

  .dkYUnm {
    row-gap: 10px
    }

  .jsfAzf {
    margin-bottom: 26px
    }

  .fQQzKY {
    background: blue300
    }

  .esYcng {
    color: blue300
    }

  .bMyjCT::before,.koJGIO::after {
    content: "”"
        }

  .cpnrKk {
    box-shadow: true
    }

  .hkVfPD {
    max-width: 500px
    }

  .fzcUOo {
    min-height: 50vh
    }

  .fJAmEQ {
    border-top-width: 1px
    }

  .giSVLJ {
    border-top-style: solid
    }

  .hCeGmv {
    border-top-color: var(--hzpwrB)
    }

  .ATIAQ {
    padding: 0px
    }

  .aLAcH {
    overflow-x: overlay
    }

  .ilrZna::-webkit-scrollbar {
    width: 1px
        }

  .cvxzCR::-webkit-scrollbar {
    height: 1px
        }

  .cFYBWM::-webkit-scrollbar-track {
    width: 1px
        }

  .kuNrMU::-webkit-scrollbar-track {
    background: var(--lcYDiE)
        }

  .jhiexZ::-webkit-scrollbar-thumb {
    width: 1px
        }

  .cPESLr::-webkit-scrollbar-thumb {
    background: $neutral0
        }

  .csimbe::-webkit-scrollbar-thumb {
    border-radius: 20px
        }

  .iEnexj {
    padding: 0 1.5rem 0 0
    }

  .befPhE {
    gap: 1.3rem
    }

  [data-state=open] .fvlycQ {
    color: var(--jRGBpu)
        }

  .iYIlOa {
    justify-content: space-evenly
    }

  .MrtSM {
    border: 0
    }

  .cESAvz[data-state=open] {
    outline: 2px solid transparent;
    outline-offset: 2px
        }

  .boQIf[data-state=open] {
    color: var(--jRGBpu)
        }

  .iJHAa[data-state=open]::after {
    background: transparent
            }

  .hOYfJi::after {
    background: var(--jRGBpu)
        }

  .dSXBfT {
    padding: 1rem
    }

  .dnRcTp {
    width: 15rem
    }

  .eyxgEB {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08)
    }

  .jrHAhj {
    padding: 0 8px
    }

  .hUgObu {
    margin: 8px 0
    }

  .kBDOXO > a {
    width: 100%
        }

  .XpaTm > a {
    padding: 8px 0
        }

  .jsCBEJ {
    top: 2px
    }

  .eOaijM {
    fill: var(--kPKNfw)
    }

  .fuemSs {
    transition: transform 250ms ease
    }

  [data-state=open] .gFKEAu {
    transform: rotate(-180deg)
        }

  .kymjFY {
    padding: 1rem 0
    }

  .idUWTB::after {
    position: absolute
        }

  .fjozWd::after {
    bottom: 0px
        }

  .hjHuzt::after {
    left: 0px
        }

  .ctEymS::after {
    right: 0px
        }

  .fILCnt::after {
    height: 3px
        }

  .iAISll::after {
    width: 100%
        }

  .fxAANA::after {
    margin: auto
        }

  .kznAPZ::after {
    background: transparent
        }

  .kJxERL[data-active] {
    font-weight: var(--ghWdwL)
        }

  .YGAzq {
    padding: 8px 20px 18px 20px
    }

  .fNCnKV {
    padding-block: 4px
    }

  .dvhsMa {
    top: 45px
    }

  .gRrKkt {
    box-shadow: 0px 8px 26px 0px rgba(0, 0, 0, 0.1)
    }

  .ctYeqR {
    opacity: 0.6
    }

  .fKqzMa {
    fill: $neutral400
    }

  .iBNzH[data-highlighted] {
    background: var(--hzpwrB)
        }

  .cKshSP[data-highlighted] {
    outline-color: var(--iCdJRO)
        }

  .dnFyWD {
    width: 100vw
    }

  .djBLSD {
    height: 100vh
    }

  .dKNwtD {
    animation-name: slideUpAndFade
    }

  .hTHvES {
    padding: 0px 0px 180px 8px
    }

  .diVlLl {
    max-height: 100%
    }

  .kZFrYs {
    max-width: 37rem
    }

  .bnJZrc > div {
    width: 100%
        }

  .fYMXgh {
    border: unset
    }

  .cFijiJ {
    font-weight: $semibold
    }

  .kPSrjC {
    fill: var(--jRGBpu)
    }

  .jAgERT {
    width: 1.45rem
    }

  .jAgESg {
    height: 1.45rem
    }

  .fNBpGf {
    margin-right: 8px
    }

  .fNCZfM {
    margin-top: 1px
    }

  .jrzAVX {
    border-radius: 48px
    }

  .bTibYs {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2)
    }

  .eVHffH {
    --thickness: 1px
    }

  .gsLSP {
    border-block-end-width: var(--thickness)
    }

  .kSMjHy {
    fill: $purple50
    }

  .dFJKVe {
    fill: $neutral200
    }

  .haJVih::-webkit-scrollbar-thumb {
    background: $neutral200
        }

  .eVTKog {
    transition: backgroundColor 160ms ease-out
    }

  .QMXSi[data-orientation="vertical"] {
    width: 5px
        }

  .kdmoox[data-orientation="horizontal"] {
    height: 10px
        }

  .chwTNO[data-orientation="horizontal"] {
    padding: 2px
        }

  .nbjdd[data-orientation="horizontal"] {
    flex-direction: column
        }

  .fATUoA::before {
    top: 50%
        }

  .fhGsPU::before {
    left: 50%
        }

  .iBeoNo::before {
    width: 100%
        }

  .bQzjfr::before {
    height: 100%
        }

  .kwkvCb::before {
    min-width: 44px
        }

  .jtRTFI::before {
    min-height: 44px
        }

  .fXGMkz::before {
    transform: translate(-50%, -50%)
        }

  .pCOxQ {
    width: 380px
    }

  .jyqLHC {
    gap: 84px
    }

  .fzoybu {
    width: 50
    }

  .fzoybJ {
    height: 50
    }

  .pBmzh {
    width: 420px
    }

  .pCMWT {
    height: 780px
    }

  .pBlIQ {
    height: 220px
    }

  .dHPPYk {
    min-width: 80px
    }

  .cJywOO:not(:last-of-type) {
    border-bottom: 1px solid $neutral100
        }

  .evAFIL {
    background: orange200
    }

  .Vergy {
    background: purple100
    }

  .fzpnVC {
    padding-bottom: 0
    }

  .dfSvRV {
    -webkit-line-clamp: 7
    }

  .iJmwPw {
    border-bottom-left-radius: 0
    }

  .eVWwOu {
    border-bottom-right-radius: 0
    }

  .gtcNzp {
    list-style-type: decimal
    }

  .bMTXvS > li::marker {
    color: $neutral500
        }

  .DBSId > li::marker {
    font-weight: $bold
        }

  .kjZEuw {
    width: 80.7%
    }

  .hpczDk {
    z-index: 10
    }

  .mFIGl {
    width: 65%
    }

  .kAMfWY {
    color: orange500
    }

  .mFboD {
    width: 94%
    }

  .eAOuUH {
    width: 80.7%
    }

  .ejnlhO[disabled] {
    opacity: 0.5
        }

  .dkGpku > label > button {
    height: 100%
        }

  .dUwplv > label > button span {
    font-size: 14px
            }

  .hdsZww > div:first-child {
    height: 100%
        }

  .ibIbUh > div:first-child {
    display: block
        }

  .lcATZY {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px
    }

  .bjxZDr {
    content: Discussion content
    }

  .bTqFmN {
    max-width: 380px
    }

  .jutGZS {
    width: inherit
    }

  .mGaRj {
    width: 58%
    }

  .kgQdbp {
    text-transform: capitalize
    }

  .kZIndW {
    background: $neutral200
    }

  .coifNq {
    margin-top: auto
    }

  .bFviaR {
    border-top: 1px solid $neutral100
    }

  .fRxJwL {
    transition: 0.5s
    }

  .frJqtw {
    opacity: 1
    }

  .gesuPQ {
    transition: display 0s linear 150ms, opacity 100ms
    }

  .dxVcBS {
    line-height: initial
    }

  .pANha {
    width: 100px
    }

  .jGeaAw {
    border-radius: 16px
    }

  .jFiBMA {
    border-top-right-radius: 0
    }

  .lrVGS {
    border-top-left-radius: 0
    }

  .cSkMUD {
    touch-action: none
    }

  .jrPWpc {
    margin-top: 14px
    }

  .dpVWXJ {
    background-color: var(--fcfsVb)
    }

  .ATHrf {
    height: 3px
    }

  .gaVLEM {
    background-color: var(--iCdJRO)
    }

  .lkYUqX {
    border-radius: 9999px
    }

  .MnjmW {
    box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.2)
    }

  .jZwGWY {
    fill: white
    }

  .logPHg {
    border-spacing: 0
    }

  .iweAQv {
    border-collapse: collapse
    }

  .hwovXb {
    id: id
    }

  .iZRkjM {
    -header: ID
    }

  .dEKMYa {
    id: title
    }

  .jFqbiC {
    -header: Título
    }

  .jsvgpT {
    width: 480px
    }

  .jyUHZl {
    line-height: 18px
    }

  .debKnh {
    flex-basis: 232px
    }

  .hJTHvf {
    border-bottom: 3px solid transparent
    }

  .fuRFhn {
    border-right-width: 1px
    }

  .jxyZiS {
    border-right-style: solid
    }

  .bMcMqJ {
    border-right-color: var(--hzpwrB)
    }

  .iXjEqo:last-child {
    border-right: none
        }

  .IONcp {
    border-top-left-radius: 12px
    }

  .jEsDyj {
    border-top-right-radius: 12px
    }

  .fNCaoV {
    padding: 36px
    }

  .xJXFy {
    margin: 26px 0
    }

  .jgsQRZ {
    max-lines: 1px
    }

  .kUuoSd {
    text-decoration: underline
    }

  .cCiQNE {
    text-decoration-color: $purple500
    }

  .oLWVN {
    font-weight: var(--TgeWs)
    }

  .jwdzKD {
    font-size: 3.5rem
    }

  .bTiqTi {
    line-height: 4.875rem
    }

  .dnSlaw {
    font-size: 3rem
    }

  .icBWJR {
    line-height: 4.188rem
    }

  .dnRxXd {
    font-size: 2rem
    }

  .iZbKos {
    line-height: 2.813rem
    }

  .jweaoN {
    font-size: 1.5rem
    }

  .kcboKw {
    line-height: 2.125rem
    }

  .RiFXn {
    line-height: 1.75rem
    }

  .jNTsHV {
    line-height: 1.563rem
    }

  .VJSWr {
    font-size: 0.875rem
    }

  .fwwCwn {
    line-height: 1.125rem
    }

  .dkBJcc {
    font-size: 0.625rem
    }

  .eyuGYa {
    line-height: 0.875rem
    }

  .dUCZAQ {
    line-height: 1.375rem
    }

  .hkAhgm {
    line-height: 1.25rem
    }

  .jYbFmc {
    font-family: var(--itGdHF)
    }

  .gNwNWp {
    font-family: var(--iCoXBY)
    }

  .fNlgJl:is(:disabled, [disabled], [data-disabled]) {
    text-decoration: line-through
        }

  .jOusEE[data-state="open"] {
    animation-name: slideUpToast
        }

  .gHGIUV[data-state="closed"] {
    animation-name: slideDownToast
        }

  .kiPepn {
    bottom: 15px
    }

  .gRUupw {
    right: 0px
    }

  .fzozCb {
    z-index: 10
    }

  .dVuHSt {
    margin: 0 12px
    }

  .hEzNkW {
    list-style: none
    }

  .gXXGM {
    background-color: $blue300
    }

  .pSflG {
    font-size: 12px
    }

  .khtefB::after {
    position: absolute
        }

  .dpdlUM::after {
    top: -15px
        }

  .GJzOc::after {
    right: 3%
        }

  .iHnzIY::after {
    border-width: 10px
        }

  .cJZruV::after {
    border-style: solid
        }

  .lmmGgG::after {
    border-color: transparent transparent $blue300 transparent
        }

  .ZBsJq {
    border-right: 1px solid $neutral0
    }

  .hiQnQK path {
    fill: $neutral0
        }

  .klEPAG {
    visibility: visible
    }

  .kddtGC {
    fill: white
    }

  .xLcyZ {
    text-shadow: 0px 1px, 1px 0px, 1px 1px
    }

  .bWCxQe {
    color-scheme: orange300
    }

  .fNCCtQ {
    width: 30px
    }

  .fNCCuj {
    height: 30px
    }

  .jRYqSG {
    margin-top: 300px
    }

  .kcyiTR {
    margin-top: -32px
    }

  .fAjnBD {
    list-style: decimal
    }

  .kZMTyx {
    list-style: inherit
    }

  .jcYdOT {
    color: #6d68ad
    }

  .fzpnWM {
    padding-left: 0
    }

  .fzpofe {
    padding-right: 0
    }

  .chmhDV {
    overflow-wrap: break-word
    }

  .gtuEnk {
    fill: $purple100
    }

  .hfGVJF {
    max-width: content-fit
    }

  .dnRYAL {
    margin-top: 4rem
    }

  .dnRyfI {
    margin-block: 2rem
    }

  .dKYCVg {
    min-width: 220px
    }

  .kKYCuT {
    flex: 1 1 0
    }

  .gCVUCm {
    flex: 1 1 1
    }

  .kVuWDV {
    row-gap: 20px
    }

  .gRZXfS {
    column-gap: 0px
    }

  .jsxjDx {
    height: 100px
    }

  .jsxicO {
    width: 500px
    }

  .btkxWq {
    background-color: $purple500
    }

  .hbXOBg {
    display: inline-grid
    }

  .AxhTk {
    padding: 4px
    }

  .fCfxFJ {
    object-fit: contain
    }

  .isoXxi {
    object-position: center
    }

  .MbbMm {
    min-width: 240px
    }

  .dnRyhX {
    margin-bottom: 2rem
    }

  .ATHag {
    padding: 4px
    }

  .fNBMXP {
    margin-top: 6px
    }

  .pADNR {
    margin-top: 40px
    }

  .insEvP {
    display: -webkit-box
    }

  .cYtcUG {
    -webkit-line-clamp: 4
    }

  .bgiDz {
    -webkit-box-orient: vertical
    }

  .BnHJO {
    fill: $neutral0
    }

  .jwOwWk {
    gap: 24px
    }

  .jrQGkH {
    margin-top: 64px
    }

  .jswujA {
    height: 150px
    }

  .haoJNg path {
    fill: $purple500
        }

  .fNBMTN {
    margin-bottom: 6px
    }

  .ibNUXG {
    background-color: var(--lcYDiE)
    }

  .cvzpyt {
    border: 1px solid #C1C1C1
    }

  .PKZfB {
    max-width: 425px
    }

  .gyBGfY {
    border-radius: 25px
    }

  .jwXuUr {
    gap: 14px
    }

  .emOUtC > path {
    stroke: $purple500
        }

  .jORnMs {
    border-radius: 12px
    }

  .pzMdC {
    width: 140px
    }

  .fzoXKZ {
    height: 32px
    }

  .fgQeHP {
    border-radius: 40px
    }

  .hSQiSh {
    align: start
    }

  .hPZrEY {
    content: content
    }

  .cXdxXm {
    white-space: pre-line
    }

  .fpKwSM {
    word-break: break-all
    }

  .duwSYm {
    margin-bottom: -15px
    }

  .gzUhxP {
    box-shadow: unset
    }

  .dSXBWl {
    height: 3rem
    }

  .bZoLDB {
    line-height: unset
    }

  .dyuLZr::before {
    height: 90%
        }

  .lcWXmC::before {
    background-color: $neutral100
        }

  .fdNcbx {
    grid-template-columns: 3fr auto
    }

  .WlYZN {
    grid-template-areas: "cards actions"
    }

  .bRIYat {
    align-items: end
    }

  .kJdlKL {
    gap: 18px
    }

  .kccLZb {
    gap: 32px
    }

  .jevwQI {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1)
    }

  .plugi {
    margin-top: 56px
    }

  .bwkCJE {
    font-weight: 300
    }

  .eIhNNB {
    gap: 30x
    }

  .fNBpvX {
    margin-bottom: 8px
    }

  .jsNJXj {
    margin-top: 42px
    }

  .fNDlqa {
    padding-bottom: 0px
    }

  .RXhja {
    color-scheme: purple500
    }

  .lhUqNC {
    border-top: 1px solid $neutral200
    }

  .ntYOT {
    grid-template-columns: repeat(1, 1fr)
    }

  .hZMpKn {
    font-size: 1.25rem
    }

  .biCzNl {
    min-width: 80%
    }

  .ioQzEH {
    font-size: 0.75rem
    }

  .gaVLEM {
    background: var(--iCdJRO)
    }

  .gCYmBr {
    color: var(--hzpwrB)
    }

  .gadUIt {
    color: pink
    }

  .bGXiKH {
    grid-template-columns: 1fr 1fr auto
    }

  .gNGxDI {
    grid-template-areas: "title title title" "createdAt status button" "updatedAt completedAt button";
    }

  .jgBAgm {
    border-color: transparent
    }

  .hEqvgH:first-of-type {
    padding-left: 0
        }

  .izFtkh:nth-child(2) {
    grid-area: createdAt
        }

  .enAQGm:nth-child(4) {
    grid-area: updatedAt
        }

  .dFoSef:nth-child(5) {
    grid-area: completedAt
        }

  .cFwgmi:nth-child(6) {
    grid-area: button
        }

  .rmyfW {
    gap: 8px
    }

  .fNzOcQ {
    fill: $purple500
    }

  .fggooN {
    fill: $pink400
    }

  .cEoiiS {
    fill: $purple500
    }

  .jkxprz>a {
    color: $purple500
        }

  .PdZfa>a {
    font-weight: 800
        }

  .iiOdSw>a {
    text-decoration: underline
        }

  .jrLcAl {
    grid-area: activities
    }

  .dnRYMd {
    margin-bottom: 4rem
    }

  .eaLlYw {
    gap: 1.25rem
    }

  .pSflM {
    xs: 12px
    }

  .fzoAYA {
    lg: 3px
    }

  .fzopZB {
    md: 4px
    }

  .SqPKP {
    grid-template-rows: repeat(5, auto)
    }

  .dIyuYM {
    grid-template-columns: repeat(2, 1fr)
    }

  .eFakSc {
    grid-template-areas: "welcome welcome" "activities activities" "title title" "cards cards" "button button";
    }

  .iJyHAk {
    grid-area: welcome
    }

  .eqWucg {
    grid-area: button
    }

  .ekaVMM {
    justify-self: center
    }

  .gZHoSV {
    grid-area: cards
    }

  .fNBDkV {
    width: 64px
    }

  .fNBDlm {
    height: 64px
    }

  .jswIZw {
    width: 160px
    }

  .jtApWh {
    gap: 120px
    }

  .jsgMzt {
    padding-bottom: 16px
    }

  .jsgMwj {
    margin-block: 16px
    }

  .dnRKKt {
    padding-bottom: 1rem
    }

  .heOGKd {
    border-bottom-color: var(--hzpwrB)
    }

  .dnRKCv {
    margin-block: 1rem
    }

  .fZAeZb {
    gap: 0.5rem
    }

  .jziLTN::before {
    width: 0.375rem
        }

  .hojMeH::before {
    height: calc(100% - 3.266rem)
        }

  .jUDgIf::before {
    background: #e7e7e7
        }

  .bdGsvH::before {
    top: 3.563rem
        }

  .jShrey::before {
    left: 2px
        }

  .jTcDmp::before {
    border-radius: 0.375rem
        }

  .dnRKKU {
    margin-bottom: 1rem
    }

  .dbFbeT::before {
    background: #c1c1c1
        }

  .dLVhZe::before {
    content: ""
        }

  .ePDhEm::before {
    display: block
        }

  .jrpcxw::before {
    position: absolute
        }

  .cmShkX::before {
    top: 0.438rem
        }

  .kJeJSA::before {
    left: 0px
        }

  .iYBpGR::before {
    margin: auto
        }

  .diIxiR::before {
    width: 0.625rem
        }

  .kLROsC::before {
    height: 0.625rem
        }

  .jNMekb::before {
    background: var(--jRGBpu)
        }

  .cHxlUY::before {
    border-radius: 50%
        }

  .hZMpOP {
    margin-left: 1.25rem
    }

  .cWdJLO {
    font-size: 1.125rem
    }

  .dSXBCd {
    width: 4rem
    }

  .dSXBCC {
    height: 4rem
    }

  .gHPrAW > svg {
    width: 60%
        }

  .jkMpDd > svg {
    height: 60%
        }

  .ieuUhS > svg {
    fill: var(--lcYDiE)
        }

  .lgzTHw {
    background: var(--ksTgfC)
    }

  .cGbIhs {
    background: var(--nXIqK)
    }

  .bNefid {
    background: var(--gBjjlD)
    }

  .elDRgW {
    background: var(--khfuXU)
    }

  .dmwDIa {
    color: purple100
    }

  .lkAgRm {
    border-radius: $base
    }

  .fJnGgS {
    color-scheme: green
    }

  .hmZtNZ {
    color-scheme: orange
    }

  .kcZWet {
    color-scheme: pink
    }

  .bzxqeI {
    color-scheme: blue300
    }

  .fNBOjo {
    width: 25px
    }

  .fNBOjP {
    height: 25px
    }

  .fYNZJr {
    color: #6D68AD
    }

  .ggIJka {
    margin-top: 10rem
    }

  .dnSOpP {
    width: 20rem
    }

  .frGJoZ {
    border-radius: 25%
    }

  .gfZAyZ {
    margin: 0 1rem
    }

  .pSfoy {
    margin-top: 12px
    }

  .pBppg {
    margin-top: 10px
    }

  .oTfOI {
    margin-bottom: 34px
    }

  .igvMzt div {
    width: 100%
        }

  .jfGWkY button {
    display: flex
        }

  .dXbiAS button {
    flex-direction: row
        }

  .GlqeL button {
    align-items: flex-start
        }

  .coTPld button {
    border: none
        }

  .cfalMc button {
    background-color: transparent
        }

  .kEeGCF button {
    text-align: left
        }

  .kTjULz button {
    padding-top: 0
        }

  .zhGtp button {
    padding-bottom: 0
        }

  .jzuiJt {
    flex-shrink: 0
    }

  .fQqzPh {
    background-color: #F1F1F1
    }

  .gDUWcR p:nth-child(2) {
    color: #5B5792
        }

  .dFYBAE p:nth-child(2) {
    font-weight: 600
        }

  .fAUQJg:after {
    content: "Faltou você preencher esse(s) campo(s)"
        }

  .fvDjhx:after {
    display: block
        }

  .bCLBOn:after {
    width: 100%
        }

  .iESOuW:after {
    color: #FF4646
        }

  .hhZCnb:after {
    position: absolute
        }

  .cRlpqX:after {
    top: 100%
        }

  .gijliL:after {
    left: 0
        }

  .OptZW:after {
    transform: translateY(8px)
        }

  .pBbiX {
    margin-top: 20px
    }

  .kqBFWf {
    border-color: #FF4646
    }

  .gXLTzq {
    border-color: $neutral300
    }

  .qbege {
    gap: 0
    }

  .hfKhcw {
    id: description
    }

  .eYpOKu {
    display: grid
    }

  .gFiPYv {
    grid-template-rows: repeat(3, auto)
    }

  .buhTpp {
    grid-template-columns: repeat(5, 1fr)
    }

  .fREasp {
    grid-template-areas: "title title title title title" "status status date date ." "button button button button button";
    }

  .gcCIbu {
    text-align: left
    }

  .gghsFc {
    padding: 0
    }

  .etFyHv:nth-child(1) {
    grid-area: title
        }

  .kDpcps:nth-child(2) {
    grid-area: date
        }

  .fgIsbl:nth-child(3) {
    grid-area: status
        }

  .hZqZwY:nth-child(4) {
    grid-area: button
        }

  .cjVWTW {
    display: none
    }

  .bYiMDC:first-of-type {
    display: table-cell
        }

  .feaBhE {
    max-width: min-content
    }

  .bgOacu {
    background-color: #FFFFFF
    }

  .fldVJk {
    fill: $purple100
    }

  .Axjal {
    padding: 1px
    }

  .hkIdtl {
    margin: 0 auto
    }

  .gWcfgT {
    min-width: fit-content
    }

  .jZExJj {
    border-color: $neutral200
    }

  .hXZRxg {
    border-bottom: 1px solid
    }

  .OflMY {
    border-bottom-color: $neutral200
    }

  .MgSnL {
    color: purple
    }

  .fNAzWg {
    height: 48px
    }

  .jespgB {
    border-color: var(--jRGBpu)
    }

  .diIxfU {
    outline: 2px solid transparent;
    outline-offset: 2px
    }

  .gJXDQQ {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1)
    }

  .Brknr {
    animation-duration: 400ms
    }

  .efvJEH {
    will-change: transform, opacity
    }

  .jdLVDu {
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1)
    }

  .fUXwXY[data-state="open"] {
    animation-name: showSelectStatus
        }

  .jnHekA {
    all: unset
    }

  .bGySga {
    -webkit-user-select: none;
    user-select: none
    }

  .kZlIRW {
    border-radius: var(--jEpJZE)
    }

  .jHhhfS {
    transition: all .2s ease-in-out
    }

  .iTwNWR[data-highlighted],.ljafPj:hover {
    background: #F1F1F1
        }

  .jwemQO {
    margin-top: 2.5rem
    }

  .rTQUn {
    border: 0.063rem solid
    }

  .lgXcyo {
    padding: 2rem 2.5rem
    }

  .hVmEvv {
    padding: 0 2.5rem 1.5rem
    }

  .goRrkY {
    border-bottom: 0.063rem solid
    }

  .fTQHgh {
    padding: 1.5rem 2.5rem 0.75rem
    }

  .jrhBWb {
    margin-bottom: 48px
    }

  .jhBaxt {
    border-top: 1px solid #F1F1F1
    }

  .hdAwi {
    position: fixed
    }

  .Axjab {
    z-index: 1
    }

  .juGdZ {
    justify-content: left
    }

  .jdNDAE {
    color: #C1C1C1
    }

  .jxWsfE {
    color: #FFF
    }

  .kyGdCf {
    bottom: 0
    }

  .hEMWys {
    background-color: $green200
    }

  .kujFLy {
    flex: 0 0 40%
    }

  .huNcgb {
    flex: 0 0 100%
    }

  .fzpnVj {
    margin-bottom: 0
    }

  .hKPDFh {
    color: neutral100
    }

  .kdHsXs {
    flex-grow: 1
    }

  .kXOLxG {
    align: center
    }

  .fvBKJs {
    justify: flex-start
    }

  .QJyBJ {
    list-style: disc
    }

  .coifOA {
    margin-right: auto
    }

  .coWzBP {
    fill: $neutral100
    }

  .nVRGN {
    display: inline
    }

  .jeMliD {
    fill: $neutral100
    }

  .laipGL {
    background-color: $green500
    }

  .lWYmQ {
    background: neutral0
    }

  .ijiAiy {
    background-color: black
    }

  .mFVVB {
    height: 88%
    }

  .hrpjMh {
    color: pink400
    }

  .fNCBmJ {
    width: 60px
    }

  .fNCBni {
    height: 60px
    }

  .fIkbkl:is(:disabled, [disabled], [data-disabled]) {
    opacity: 0.4
        }

  .fgFlnb:is(:disabled, [disabled], [data-disabled]) {
    filter: grayscale(1)
        }

  .UYZQB {
    color: var(--eLlnzT)
    }

  .dnRxYZ {
    margin-top: 2rem
    }

  .SQTQB {
    align-content: start
    }

  .dfyspt {
    border: 10px
    }

  .hZMpUV {
    margin-bottom: 1.25rem
    }

  .fGMdvq {
    background: var(--kxGpio)
    }

  .bvXpFt {
    border-color: var(--fwcFBW)
    }

  .bScBai {
    fill: var(--QMCLK)
    }

  .cqqKDl {
    color: var(--iYfRb)
    }

  .fNCaYE {
    margin-left: 5px
    }

  .jrPIoj {
    font-size: 24px
    }

  .dIYVqF {
    border-width: 0.125rem
    }

  .jsxeSy {
    margin-top: 30px
    }

  .eqRINH {
    alignment-baseline: initial
    }

  .dsycIX {
    flex: wrap
    }

  .jsMXdW {
    margin-bottom: 32px
    }

  .jOWcvZ {
    border-radius: 24px
    }

  .jsOvuZ {
    padding-right: 12px
    }

  .jsOvNr {
    padding-left: 12px
    }

  .kUXAEg {
    border-color: var(--iYfRb)
    }

  .llcMdJ {
    background: #FFF7F4
    }

  .bFdNHJ {
    background: #EFF9FA
    }

  .hvICYb {
    background: #F0F4F7
    }

  .bFquBJ {
    background: #F3F2FD
    }

  .jsxFzg {
    margin-top: 10px
    }

  .celSdr {
    grid-template-columns: repeat(1, minmax(0, 1fr))
    }

  .ijrQpU {
    column-gap: 28px
    }

  .dAaUHp {
    grid-gap: 16px
    }

  .iirerB {
    color: var(--fcfsVb)
    }

  .fNCDjF {
    padding: 10px
    }

  .fNBDHa {
    width: 54px
    }

  .fNBDHx {
    height: 54px
    }

  .jsNJXD {
    font-size: 42px
    }

  .jqSOpC {
    border-radius: 999px
    }

  .dDBMsc {
    background: var(--jRGBpu)
    }

  .dHiLCc:is(:disabled, [disabled], [data-disabled]) {
    opacity: 0.5
        }

  .itbMCn:is(:disabled, [disabled], [data-disabled]) {
    background: var(--fcfsVb)
        }

  .gGTyfw:is(:disabled, [disabled], [data-disabled]) {
    cursor: not-allowed
        }

  .erFIQb > line {
    width: 24px
        }

  .hZlsjI > line {
    height: 24px
        }

  .bkQoWp > line {
    stroke: var(--lcYDiE)
        }

  .dJdkAc > path {
    width: 24px
        }

  .hmxwcn > path {
    height: 24px
        }

  .jyJogu > path {
    stroke: var(--lcYDiE)
        }

  .eCuHwm {
    grid-area: summary
    }

  .ckDurQ {
    justify-content: space-around
    }

  .mDYqS {
    width: 40%
    }

  .gWPHnz {
    font-family: Open Sans
    }

  .jlOfqx {
    order: 3
    }

  .gNOQHw {
    white-space: pre-wrap
    }

  .csKjtW {
    list-style: disc
    }

  .djnDNI {
    font-weight: var(--ghWdwL)
    }

  .gPEKWI {
    font-family: DM Sans
    }

  .diIErG {
    display: none
    }

  .dQqBxN,.loBcOy {
    color: $purple500
    }

  .gzHjnz {
    align-self: center
    }

  .fznBAN {
    height: 94px
    }

  .ioiKdn {
    align-self: end
    }

  .ifRCrs {
    align-self: start
    }

  .fNDbLU {
    padding: 22px
    }

  .jrYpUv {
    margin-bottom: 55px
    }

  .mFMbq {
    width: 95%
    }

  .fzoyMw {
    width: 30px
    }

  .fzoyMz {
    height: 30px
    }

  .fzozgh {
    width: 20px
    }

  .fzozgi {
    height: 20px
    }

  .bgEBTs {
    order: 2
    }

  .eKrKGL {
    order: 1
    }

  .iNjoKH {
    border: 1px solid $neutral200
    }

  .fVtoVs {
    list-style: inside
    }

  .dNnfaK {
    justify-content: start
    }

  .ifPfQV {
    text-align: start
    }

  .iMbnoH {
    font-style: italic
    }

  .fzrjVp {
    md: 8px
    }

  .pSfyc {
    lg: 12px
    }

  .dvijyR {
    background: #FAFAFA
    }

  .daptry {
    font-family: $dm-sans, sans-serif
    }

  .cELUon {
    border-top: none
    }

  .dgazDP {
    border-left: none
    }

  .iefJRx {
    border-right: none
    }

  .kussAa:last-of-type {
    border-bottom: none
        }

  .coOFpT {
    border-bottom: 1px solid #F1F1F1
    }

  .oBLik::before {
    border-radius: 50%
        }

  .hZcMUb::before {
    background-color: $neutral400
        }

  .foNwzU::before {
    position: absolute
        }

  .kkGfrh {
    container: true
    }

  .AxjtB {
    margin: 0
    }

  .fzoqhz {
    xs: 4px
    }

  .fzopUi {
    sm: 4px
    }

  .fzoAUi {
    md: 3px
    }

  .fzoNFh {
    xs: 2px
    }

  .fzoNpA {
    sm: 2px
    }

  .fzoNuH {
    md: 2px
    }

  .fzopVj {
    lg: 4px
    }

  .kQauLJ {
    align-items: baseline
    }

  .jspdqC {
    border-width: 1px
    }

  .brdJQj {
    border-radius: 8px
    }

  .fzoXKR {
    padding: 32px
    }

  .hDPoYw {
    background: $neutral0
    }

  .cpqDVJ {
    background: $neutral100
    }

  .fptVbS::before, .fptVbS::after {
    color: $neutral400
        }

  .iTwJYx::before {
    content: attr(data-prefix)
        }

  .ecOkCL::after {
    content: attr(data-suffix)
        }

  .hWgptE {
    right: 0
    }

  .eoCBZw {
    background: purple300
    }

  .jHGcwt {
    gap: 16px
    }

  .pvuga {
    gap: 4px
    }

  .cANWyd {
    color: $neutral500
    }

  .gNOkaY {
    border-radius: 12px
    }

  .kVMpxs {
    fill: purple500
    }

  .bOFDLN {
    background: neutral100
    }

  .eWkBhE {
    background-color: inherit
    }

  .jsMAZy {
    width: 562px
    }

  .ktFGpV {
    justify-content: end
    }

  .jtpSWJ {
    flex: 1 0 auto
    }

  .hDPoYw {
    background-color: $neutral0
    }

  .plGGH {
    margin-top: 46px
    }

  .fceQwo {
    border-bottom: 1px dashed $purple300
    }

  .ggMOBv:last-child {
    border: none
        }

  .jweaaf {
    margin-bottom: 1.5rem
    }

  .kMxccx {
    color: $purple200
    }

  .fNDlGl {
    margin-top: 0px
    }

  .laSzyB {
    min-width: 40%
    }

  .fWEYPy {
    min-width: 30%
    }

  .efgyKL {
    border-radius: 15px
    }

  .fNCcFz {
    padding: 16px
    }

  .cTQaTk {
    gap: 28px
    }

  .fNABda {
    width: 18px
    }

  .fNBNen {
    padding-block: 6px
    }

  .jsxFBV {
    padding-inline: 10px
    }

  .ebYfJ {
    border-radius: 6px
    }

  .dSXBfY {
    width: 1rem
    }

  .dSXBgr {
    height: 1rem
    }

  .bUyOMB {
    display: inline-block
    }

  .hryFmx {
    background: var(--iYfRb)
    }

  .dBjgew {
    background: var(--fwcFBW)
    }

  .gaZWht {
    background: var(--jdlUVz)
    }

  .fNABdh {
    height: 18px
    }

  .cXULXl\! > span.react-loading-skeleton {
    width: 70% !important
        }

  .eKyOFb\! > span.react-loading-skeleton {
    width: 50% !important
        }

  .yShwg\! > span.react-loading-skeleton {
    width: 35% !important
        }

  .MCmjh > span.react-loading-skeleton {
    height: 32px
        }

  .esnawY {
    min-height: 250px
    }

  .fNBpMl {
    margin-top: 8px
    }

  .jrhZaH {
    padding-block: 28px
    }

  .fTQKnz {
    min-height: 55px
    }

  .clTbrf {
    color: var(--eQKgRp)
    }

  .dnQMxG {
    margin-top: 5rem
    }

  .jFoWhZ {
    gap: 1.6rem
    }

  .bDaMeR {
    grid-template-areas: "header" "title" "filters" "tasks"
    }

  .fcNluX {
    padding: 20px 24px
    }

  .kVcUAq {
    grid-area: title
    }

  .fNCaIE {
    padding: 26px
    }

  .jsxFta {
    margin-right: 10px
    }

  .gaZWht {
    background-color: var(--jdlUVz)
    }

  .jrhMTs {
    margin-bottom: 38px
    }

  .jsolfN {
    margin-bottom: 17px
    }

  .kgNTQh {
    padding: 24px 30px 0
    }

  .gHoTrU {
    border-radius: 21px
    }

  .hpAfxi {
    margin: 0 -26px 28px
    }

  .gPyWWT {
    border: 1px solid
    }

  .kijdVA {
    background: green200
    }

  .bdKqxD {
    background: yellow200
    }

  .AxjtU {
    padding: 0
    }

  .eqLRXZ {
    min-width: 140px
    }

  .fNCapt {
    height: 36px
    }

  .cJqtqn > div {
    position: relative
        }

  .CbdAF > div {
    top: unset
        }

  .iktoVW > div {
    right: unset
        }

  .jjqSzC > div {
    transform: unset
        }

  .eqocSa {
    fill: $neutral400
    }

  .bApKNf {
    stroke: $purple500
    }

  .fznycS {
    height: 24px
    }

  .fznycJ {
    width: 24px
    }

  .jShLkw {
    gap: 20px
    }

  .oTspc {
    padding-bottom: 24px
    }

  .pBpuh {
    padding-left: 10px
    }

  .pBpjv {
    padding-right: 10px
    }

  .pCORB {
    width: 280px
    }

  .kKspxs {
    color: var(--lbfZji)
    }

  .jzIORq {
    padding: 0.75rem
    }

  .cvNWJc {
    margin: 0.75rem 0
    }

  .eaQgZD {
    gap: 0.625rem
    }

  .jzlbhu {
    height: 1.25rem
    }

  .jzlbht {
    width: 1.25rem
    }

  .cXKziW {
    padding: 0.8125rem
    }

  .hhWyss {
    font-weight: normal
    }

  .coXRbg {
    gap: 0.75rem
    }

  .gTdibc {
    background-color: var(--lbfZji)
    }

  .iortIh {
    padding: 0.375rem
    }

  .eOstsf svg {
    fill: var(--kPKNfw)
        }

  .blzRuZ svg {
    display: none
        }

  .huNDax svg {
    fill: var(--jRGBpu)
        }

  .gSkZrh svg {
    width: 1.5625rem
        }

  .dAGZtO svg {
    height: 1.5625rem
        }

  .gBuQJ {
    color: var(--jRGBpu)
    }

  .iUFTor {
    gap: 0.375rem
    }

  .eaDpld {
    gap: 0.25rem
    }

  .pkweu {
    margin-top: 16px
    }

  .clTbrf,.ldgyDm::placeholder {
    color: var(--eQKgRp)
        }

  .pAOTY {
    margin-bottom: 30px
    }

  .pADWH {
    margin-bottom: 40px
    }

  .pSfvc {
    margin-bottom: 12px
    }

  .ekHCQz {
    height: 85vh
    }

  .iFfglO {
    color: #333333
    }

  .jUeDOx {
    align-items: left
    }

  .pSfkU {
    margin-right: 12px
    }

  .biCIyz {
    max-width: 80%
    }

  .bMeGfo {
    text-overflow: ellipsis
    }

  .emmCgF {
    max-width: 60%
    }

  .dXscgu {
    overflow: hidden
    }

  .dgzwbo {
    object-fit: cover
    }

  .kZIndW {
    background-color: $neutral200
    }

  .fzpabv {
    padding: 12px
    }

  .DyZUq {
    border-radius: 10px
    }

  .dpYiap {
    border: 3px solid $purple500
    }

  .fNAEyf {
    width: 88px
    }

  .fNAEyA {
    height: 88px
    }

  .jswUeV {
    padding-block: 40px
    }

  .jsMWXN {
    padding-inline: 32px
    }

  .dzepVL {
    gap: 36px
    }

  .faCDPQ {
    max-width: 424px
    }

  .mDYKD {
    width: 50%
    }

  .jswiAi {
    width: 140px
    }

  .fNCABX {
    width: 80px
    }

  .fNCACc {
    height: 80px
    }

  .jXknIp {
    background: var(--cbZZGT)
    }

  .eXGrsE {
    border-width: 12px
    }

  .dPJXEL {
    border-color: var(--kxGpio)
    }

  .hekxvX {
    color: $neutral300
    }

  .VXXUO {
    justify-self: flex-end
    }

  .jrQGtP {
    padding-inline-end: 64px
    }

  .bMdgrd {
    max-height: 400px
    }

  .jsgMpR {
    padding-right: 16px
    }

  .gfYZhR {
    gap: 1rem
    }

  .kdFIjO {
    flex: 1 1 0%
    }

  .fNDbsl {
    padding: 32px
    }

  .fNBAFa {
    padding-block: 7px
    }

  .jsMWUY {
    margin-top: 32px
    }

  .fBSDku {
    color: var(--lcYDiE)
    }

  .dpVWXJ {
    background: var(--fcfsVb)
    }

  .leOtqz {
    border-radius: 8px
    }

  .mDZRu {
    width: 80%
    }

  .jsOvBS {
    padding-block: 12px
    }

  .jsgMwf {
    padding-inline: 16px
    }

  .bkLbNa {
    background: var(--bMEoOM)
    }

  .jvQYvV {
    align-self: flex-end
    }

  .hXjSXb {
    border-bottom-left-radius: 16px
    }

  .hDGJaH {
    border-style: solid
    }

  .erSXch {
    border-color: var(--lbfZji)
    }

  .ffINNh {
    border-top-left-radius: 16px
    }

  .cdZjjz {
    border-top-right-radius: 16px
    }

  .gNllbh {
    border-bottom-right-radius: 16px
    }

  .hnWEwc {
    bottom: 16px
    }

  .HVViH {
    right: 12px
    }

  .fNCCcz {
    width: 40px
    }

  .fNCCdc {
    height: 40px
    }

  .bbZFbP {
    background: var(--dWAXWh)
    }

  .kFAHfq:disabled {
    background: var(--lcYDiE)
        }

  .ieedSa:disabled {
    cursor: not-allowed
        }

  .bRTCcd:disabled > svg {
    fill: var(--fcfsVb)
            }

  .cwelCu > svg {
    fill: white
        }

  .hjQFvE > svg {
    margin-top: -2px
        }

  .jyhWUk > svg {
    transform: rotate(-45deg)
        }

  .fNBBKd {
    width: 24px
    }

  .fpdhwI {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1)
    }

  .XAzZz {
    border-radius: 4px
    }

  .eGOGGH {
    transition: all .3s ease-in-out
    }

  .fnmjuQ > svg {
    width: 18px
        }

  .hTCpUn > svg {
    height: 18px
        }

  .eeRAic > svg {
    fill: var(--iCdJRO)
        }

  .bcYix > svg {
    border-style: solid
        }

  .iwZiro > svg {
    border-width: 1px
        }

  .chIaEP > svg {
    border-color: var(--iCdJRO)
        }

  .eyQlpV > svg {
    border-radius: 100%
        }

  .ATFzz {
    width: 8px
    }

  .ATFyY {
    height: 8px
    }

  .gvxcDU {
    background: var(--QMCLK)
    }

  .jsfnTk {
    padding-block: 36px
    }

  .jsOvBn {
    padding-inline: 12px
    }

  .fNGMbY {
    border-style: dotted
    }

  .kWxMZR {
    border-width: 2px
    }

  .fNDbsm {
    width: 32px
    }

  .fNDbsJ {
    height: 32px
    }

  .IGhXC {
    padding: 6px 12px
    }

  .mDWtV {
    width: 30%
    }

  .coigcm {
    margin-left: auto
    }

  .fNCbdY {
    margin-top: 5px
    }

  .eIXWIS {
    justify-items: center
    }

  .hQJXOm {
    align-items: start
    }

  .jwcObW {
    margin-bottom: 0.5rem
    }

  .jsxrvf {
    padding-block: 20px
    }

  .jrPIqC {
    padding-inline: 24px
    }

  .jrinoA {
    margin-top: 18px
    }

  .jBTsOO {
    grid-template-rows: repeat(4, auto)
    }

  .evbWxM {
    grid-template-areas: "header" "tabs" "filters" "hours"
    }

  .jgpCVR {
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.1)
    }

  .iAIdcd {
    grid-template-areas: "actions" "filters" "hours"
    }

  .lkPBml {
    grid-area: hours
    }

  .jZExGS {
    grid-area: tabs
    }

  .eeMBPr {
    grid-area: actions
    }

  .hoLpel {
    color: $pink400
    }

  .djUvqb path {
    fill: $pink400
        }

  .cGiTTJ {
    fill: $red500
    }

  .enlWRv {
    background-color: $green100
    }

  .jqpgOS {
    color-scheme: red
    }

  .brMBZk {
    box-shadow: none
    }

  .kndkSn,.jGmyK:hover, .jGmyK:disabled {
    background-color: $red500
        }

  .bbYXSC {
    border: none
    }

  .foDtdn {
    background-color: transparent
    }

  .kEjIVT {
    background: red500
    }

  .jrXEzZ {
    padding-bottom: 25px
    }

  .sCJDc {
    border-block-color: #F1F1F1
    }

  .xJvFw {
    margin: 20px 0
    }

  .zqwkO {
    padding: 30px 30px 60px 30px
    }

  .lhJavP {
    min-height: 132px
    }

  .jrXrXv {
    margin-bottom: 15px
    }

  .jVejTd {
    margin: auto 0
    }

  .pCPnx {
    height: 180px
    }

  .pALZT {
    width: 400px
    }

  .iMQCRP {
    justify-items: flex-start
    }

  .gsynQv {
    height: fit-content
    }

  .hwfGKr,.hwfNHv {
    color: $purple500
    }

  .hpAyOK {
    background-color: $purple100
    }

  .BlHbc {
    color: $neutral0
    }

  .bheJpJ {
    font-weight: 600
    }

  .pqglx svg {
    fill: $neutral0
        }

  .dLgGxt,.dLgAvd {
    color: $neutral0
    }

  .bvxOCL {
    direction: row
    }

  .eRodXr {
    padding: 2px 10px 2px 10px
    }

  .bDmzCI {
    color: neutral0
    }

  .jsxrHT {
    margin-left: 20px
    }

  .xJXlR {
    margin: 16px 0
    }

  .dFYdIC {
    max-height: 430px
    }

  .lfAQwz {
    color-scheme: green500
    }

  .fNCCNB {
    width: 20px
    }

  .fNCCNS {
    height: 20px
    }

  .bmHsHg {
    background-color: #FFF
    }

  .cuUYds {
    border-bottom-width: 1px
    }

  .gFTnTc {
    border-color: #CCC
    }

  .jsxFtf {
    padding-right: 10px
    }

  .jsCBtQ {
    gap: 2px
    }

  .jsxrEx {
    margin-bottom: 20px
    }

  .dELUt {
    background-color: var(--iDDIAz)
    }

  .fNCnsX {
    margin-bottom: 4px
    }

  .jsxeTQ {
    margin-right: 30px
    }

  .ATFzw {
    padding: 8px
    }

  .pezuA {
    gap: 6px
    }

  .jsxFDi {
    margin-bottom: 10px
    }

  .iRRFHS {
    border-radius: 10px
    }

  .nwSji {
    margin: 15px 0px
    }

  .lozcfM {
    fill: $purple500
    }

  .jmjKqH {
    color: neutral300
    }

  .ijnSeO {
    background: rgba(155, 144, 209, 0.06)
    }

  .dEJHzc {
    border: 1px solid #F1F1F1
    }

  .fNCCNu {
    padding: 20px
    }

  .jUJMFv {
    justify-content: flex-end
    }

  .bPLuEf {
    column-gap: 14px
    }

  .jzYaaF {
    row-gap: 24px
    }

  .keIrFt {
    align-items: normal
    }

  .jsOvvq {
    font-size: 12px
    }

  .jsOvFc {
    margin-bottom: 12px
    }

  .fNCCcE {
    padding: 40px
    }

  .esOKUS {
    border-width: 1px
    }

  .jGAxYB {
    border-style: dashed
    }

  .hgDwgg {
    border-color: var(--fcfsVb)
    }

  .cxKBPO {
    color: purple50
    }

  .jsnMfm {
    gap: 4px
    }

  .jrIcfy {
    gap: 8px
    }

  .cBmMB {
    padding: 4px 2px
    }

  .byazNr {
    background-color: $yellow200
    }

  .bECwXS {
    color-scheme: purple100
    }

  .fJpwAC {
    min-width: calc(100vw - 160px)
    }

  .iMOCrA {
    min-height: 120px
    }

  .fnjBrW {
    border: 1px solid transparent
    }

  .jVROBX {
    transition: border-color 0.2s ease-in-out
    }

  .kpSxWv:nth-of-type(4n+1) {
    background: var(--cbZZGT)
        }

  .lnIzYu:nth-of-type(4n+2) {
    background: var(--dWAXWh)
        }

  .ljCfWp:nth-of-type(4n+3) {
    background: var(--eyVWNz)
        }

  .fdRATW:nth-of-type(4n+4) {
    background: var(--gBjjlD)
        }

  .jsxrsX {
    margin-top: 20px
    }

  .jsMXdv {
    padding-bottom: 32px
    }

  .hNiWkR {
    align-items: stretch
    }

  .fEVEmD {
    display: inline-flex
    }

  .eXMQhb::after {
    content: ""
        }

  .gSfmxl::after {
    width: 48px
        }

  .kyUBEG::after {
    height: 48px
        }

  .dbUNJ::after {
    border-radius: 9999px
        }

  .boAhfB::after {
    border: 4px solid transparent
        }

  .kyiTXG::after {
    border-top-color: var(--jRGBpu)
        }

  .bnxrZD::after {
    animation: rotate 1.2s linear infinite
        }

  .fZwEdu {
    padding: 20px 16px
    }

  .kpEWab {
    min-height: 480px
    }

  .jXJpHV {
    border-radius: 20px
    }

  .fNBpFn {
    padding-inline-start: 8px
    }

  .fsSVpP {
    list-style-type: disc
    }

  .jhFNDW {
    list-style-position: inside
    }

  .cvjbXA {
    outline: true
    }

  .dLmAuA {
    color: gray
    }

  .dKiwaS {
    font-weight: var(--ghWdwL)
    }

  .gYKVZj {
    background: green100
    }

  .jLQCen {
    color: purple400
    }

  .fzpols {
    padding-top: 0
    }

  .pbbCR {
    font-size: 15px
    }

  .jBHYVC {
    gap: 15px
    }

  .dmmnkM {
    min-width: 100%
    }

  .gnCPvg {
    min-height: auto
    }

  .jJjejV {
    border: 1px solid #e7e7e7
    }

  .jrhZtR {
    margin-bottom: 28px
    }

  .jswTXR {
    margin-top: 40px
    }

  .cpqDVJ {
    background-color: $neutral100
    }

  .diVeFv {
    min-height: 100%
    }

  .kWfGxP {
    font-family: $dm-san
    }

  .hCBTEt {
    font-family: $open-sans
    }

  .eqlZcQ {
    color: $neutral400
    }

  .iPoSYb {
    align-self: stretch
    }

  .iFdwoo {
    font-family: $dm-sans
    }

  .jgFzEa {
    font-weight: $bold
    }

  .eAZrqi {
    margin: auto
    }

  .dKLBtz {
    gap: 10px
    }

  .jsFyzi {
    align-self: flex-start
    }

  .eAZrpM {
    width: auto
    }

  .fzpodM {
    padding-inline: 0
    }

  .foDtdn {
    background: transparent
    }

  .iJesvW {
    border-radius: 14px
    }

  .ksWBqx {
    flex-direction: row
    }

  .gsynQA {
    width: fit-content
    }

  .gfxSKR {
    padding: 0.5rem
    }

  .dnRKwm {
    font-size: 1rem
    }

  .brQpBX {
    font-weight: 400
    }

  .cbkwRf {
    white-space: nowrap
    }

  .gTdibc {
    background: var(--lbfZji)
    }

  .lcXRnr {
    opacity: 0.8
    }

  .XXeOq {
    cursor: not-allowed
    }

  .gfxUHo {
    height: 1.5rem
    }

  .gfxUHP {
    width: 1.5rem
    }

  .dBPHsC {
    padding: 0.813rem
    }

  .dkBINI {
    margin-left: 0.625rem
    }

  .hvEjVk {
    font-size: 0.85rem
    }

  .fPpPgn {
    background-color: var(--hzpwrB)
    }

  .jrPIiT {
    margin-top: 24px
    }

  .kcZqKF {
    gap: 12px
    }

  .fNBzyz {
    width: 84px
    }

  .fNAzVT {
    width: 48px
    }

  .jsvhxa {
    width: 180px
    }

  .fNCcFX {
    height: 16px
    }

  .fNBCgN {
    height: 14px
    }

  .jsxIfX {
    width: 220px
    }

  .fNDdJn {
    height: 12px
    }

  .fNBBKC {
    height: 24px
    }

  .jsgMou {
    margin-top: 16px
    }

  .cTBlEG {
    gap: 48px
    }

  .fCdqms {
    flex: 1 1 auto
    }

  .hIhcex {
    margin-bottom: 0.688rem
    }

  .bZRhvx {
    cursor: pointer
    }

  .fzpolx {
    margin-top: 0
    }

  .ixxMfO {
    max-width: 75rem
    }

  .gcohXo {
    border-radius: 1rem
    }

  .WUSvz {
    overflow-y: auto
    }

  .iyleoH {
    overflow-x: hidden
    }

  .fzpnWB {
    margin-left: 0
    }

  .gasSYy {
    flex-wrap: wrap
    }

  .iTIAaK {
    color: $neutral200
    }

  .ddgODW {
    display: none
    }

  .rHLwk {
    text-wrap: nowrap
    }

  .dvijyR {
    background-color: #FAFAFA
    }

  .kJWAhn {
    border-radius: 50%
    }

  .gHEkMU {
    line-height: 140%
    }

  .loBcOy {
    color: $purple500
    }

  .bccLBY {
    font-weight: 700
    }

  .cXCWaT svg {
    fill: $purple500
        }

  .hwfGKr {
    color: $purple500
    }

  .fCefbS svg {
    display: inline
        }

  .dNXUzv {
    max-width: 25rem
    }

  .cANWyd,.dVSPvg::placeholder {
    color: $neutral500
        }

  .dboTeJ > div, .dboTeJ input {
    max-width: 100%
        }

  .eAZrqn {
    height: auto
    }

  .jZuDhK {
    color: white
    }

  .kjBcSz {
    direction: column
    }

  .dpVcOg {
    grid-area: tasks
    }

  .gyNnsx {
    grid-template-rows: repeat(3, auto)
    }

  .ljqXMR {
    grid-template-columns: auto
    }

  .TBwJi {
    grid-template-areas: "header" "filters" "tasks"
    }

  .kDvltB {
    grid-area: header
    }

  .dRCFAU {
    align-items: flex-start
    }

  .bfAxbh {
    grid-area: filters
    }

  .jrPWok {
    font-size: 14px
    }

  .dLpVws {
    font-weight: $semibold
    }

  .jsxjhB {
    width: 200px
    }

  .dUFBcm {
    gap: 22px
    }

  .jsOvNu {
    margin-left: 12px
    }

  .fNAAGV {
    width: 28px
    }

  .fNAAGW {
    height: 28px
    }

  .iPmasP {
    min-width: 120px
    }

  .iWbCEa {
    max-width: 8xl
    }

  .coifUy {
    margin-inline: auto
    }

  .fzoqgU {
    padding-inline: 4
    }

  .dnSlgc {
    margin-top: 3rem
    }

  .dKnOgO {
    gap: 40px
    }

  .fNBBKe {
    padding: 24px
    }

  .cTYYRr {
    gap: 18px
    }

  .hWHAvy {
    overflow-x: auto
    }

  .efWmPH {
    scroll-behavior: smooth
    }

  .lnopcr {
    position: sticky
    }

  .kiKDyH {
    left: 0
    }

  .dogSUC {
    top: 94px
    }

  .AxiDU {
    z-index: 2
    }

  .dUxEjX {
    gap: 32px
    }

  .fNBpDV {
    padding-block: 8px
    }

  .gSKPcF {
    justify-content: space-between
    }

  .ibNUXG {
    background: var(--lcYDiE)
    }

  .kVOtmC {
    color: purple500
    }

  .doAbur {
    color-scheme: purple
    }

  .dKCDvs {
    gap: 20px
    }

  .gzBMzy {
    text-align: center
    }

  .jsOvyy {
    margin-top: 12px
    }

  .dVuiVB {
    gap: 12px
    }

  .cGFOJB {
    display: block
    }

  .bicbop {
    max-height: 150px
    }

  .jswujD {
    width: 150px
    }

  .kqpdsP {
    margin: 10px auto 0
    }

  .jkfsCl {
    aspect-ratio: 1
    }

  .cYdhWw {
    display: grid
    }

  .fCyQVv {
    place-items: center
    }

  .fNCaIz {
    width: 26px
    }

  .fNCaJc {
    height: 26px
    }

  .duaKfP {
    border-radius: 100%
    }

  .bsdAgg {
    font-weight: var(--hmvyHR)
    }

  .jsgMqa {
    font-size: 16px
    }

  .kPYjPU {
    color: var(--jRGBpu)
    }

  .fPpPgn {
    background: var(--hzpwrB)
    }

  .jsvtgv {
    width: 190px
    }

  .dpjphg {
    gap: 24px
    }

  .jonSOI {
    flex: 0 0 auto
    }

  .dAaUHp {
    gap: 16px
    }

  .fNDdIP {
    padding: 12px
    }

  .bOEnLb {
    min-width: 160px
    }

  .kqUknf {
    max-width: 720px
    }

  .cLpmaF {
    overflow-y: scroll
    }

  .pBbux {
    margin-bottom: 20px
    }

  .fzopYj {
    margin-bottom: 4px
    }

  .pkwpM {
    margin-bottom: 16px
    }

  .fvBKJs {
    justify-content: flex-start
    }

  .epidOx {
    color: neutral500
    }

  .hRGnVk {
    color: neutral400
    }

  .dLFxYe {
    text-align: left
    }

  .eNUmwW {
    color: var(--kPKNfw)
    }

  .dmDyBO {
    flex-basis: 100%
    }

  .gjESwn {
    min-width: auto
    }

  .dUWvNM {
    gap: 42px
    }

  .hqnpjH {
    max-width: 559px
    }

  .fPSBzf {
    display: flex
    }

  .dmmjlu {
    max-width: 100%
    }

  .jrPIzh {
    margin-bottom: 24px
    }

  .hYZFjI {
    height: 100%
    }

  .gByVZc {
    place-content: center
    }

  .fHboSi {
    align-content: center
    }

  .dVuiVB {
    grid-gap: 12px
    }

  .jrinhs {
    margin-inline: 18px
    }

  .kMkoyi {
    grid-template-columns: repeat(4, 1fr)
    }

  .legDhJ {
    justify-content: center
    }

  .kSmiQp {
    align-items: center
    }

  .hYZFkb {
    width: 100%
    }

  .jcSTPq {
    max-width: 1229px
    }

  .gFaWPc {
    border-radius: 16px
    }

  .jTWvec {
    position: relative
    }

  .eKdpgp {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1)
    }

  .guJOpU {
    padding: 36px 24px 39px 24px
    }

  .WrQAS {
    bottom: -40px
    }

  .hFCfli {
    right: -38px
    }

  .bpYWyA {
    max-width: 35px
    }

  .btGIqf {
    max-height: 35px
    }

  .iCayax {
    bottom: -70px
    }

  .klWCxc {
    left: -28px
    }

  .eUEqui {
    max-width: 40px
    }

  .eZAzKN {
    max-height: 40px
    }

  .jpmkYU {
    top: -20px
    }

  .iIyNnT {
    left: 80px
    }

  .ifKDFE {
    max-width: 53px
    }

  .ijqqrX {
    max-height: 53px
    }

  .jXSfKH {
    position: absolute
    }

  .dKuGOA {
    top: 30px
    }

  .jCbuMK {
    right: -12px
    }

  .hLbsOF {
    max-width: 30px
    }

  .hNLFgy {
    max-height: 30px
    }

  .fmhJeV {
    width: 30vw
    }

  .gccJxl {
    max-width: 412.46px
    }

  .kZJTni {
    max-height: 418.48px
    }

  .BbsAR {
    min-width: 320px
    }

  .iLbQFp {
    flex-direction: column
    }

  .jrPIyM {
    padding-bottom: 24px
    }

  .EcxBh {
    border-bottom-style: solid
    }

  .fUHstk {
    border-bottom-width: 1px
    }

  .ihFdiG {
    border-bottom-color: var(--lbfZji)
    }

  .jswUgH {
    margin-bottom: 40px
    }

  .jsgMzM {
    margin-bottom: 16px
    }

  .fHjhVT {
    max-height: 39px
    }

  .dxyDES {
    max-width: 80px
    }

  .gIbJOd:focus:not([aria-invalid=true], [aria-disabled=true]) {
    color: $neutral500
        }

  .HFEqz:focus:not([aria-invalid=true], [aria-disabled=true]) {
    border-color: $purple500
        }

  .eZrFBd:is(:focus, [data-focus]) {
    outline-color: var(--iCdJRO)
        }

  .RfIVc:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
        }

  .dgUSYI[data-state=closed]:hover {
    background: var(--hzpwrB)
            }

  .bDCNMI:hover {
    border: 1px solid $purple400
        }

  .dkuemK:hover > svg {
    fill: $neutral0
            }

  .imXRSX a:hover {
    text-decoration: underline
            }

  .eOBPaE:hover, .eOBPaE:focus {
    cursor: pointer
        }

  .cjuQDk:hover {
    fill: $purple100
        }

  .VNqXP:hover {
    background-color: #FAFAFA
        }

  .ekZiGc:hover:not([aria-invalid=true], [aria-disabled=true]) {
    color: $purple500
        }

  .PCxKZ:hover:not([aria-invalid=true], [aria-disabled=true]) {
    border-color: $purple500
        }

  .dmuLSm:hover {
    fill: $purple500
        }

  .FAtat::-webkit-scrollbar-thumb:hover {
    width: 1px
        }

  .kluWZh::-webkit-scrollbar-thumb:hover {
    background: var(--lcYDiE)
        }

  .huUQEA:hover, .huUQEA:focus, .huUQEA[data-active] {
    background: var(--hzpwrB)
        }

  .iPtvzI:hover,[data-state=open] .dZDcvC {
    fill: var(--jRGBpu)
        }

  .hCnnDr:hover, .hCnnDr:focus, .hCnnDr[data-active] {
    outline: 2px solid transparent;
    outline-offset: 2px
        }

  .hRPFDH:hover, .hRPFDH:focus, .hRPFDH[data-active] {
    color: var(--jRGBpu)
        }

  .dfXcsl:hover::after, .dfXcsl:focus::after, .dfXcsl[data-active]::after {
    background: var(--jRGBpu)
            }

  .chcUBl:hover, .chcUBl:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
        }

  .jfPuBn:hover, .jfPuBn:focus {
    font-weight: var(--ghWdwL)
        }

  .kYDJMV:hover, .kYDJMV:focus {
    color: var(--jRGBpu)
        }

  .jWPqeG:is(:hover, [data-hover]) {
    color: var(--lcYDiE)
        }

  .iGFlOc:is(:hover, [data-hover]) {
    background: var(--iCdJRO)
        }

  .dwyePR:hover [data-notification-icon] {
    background: var(--lbfZji)
        }

  .jIqcKO:hover {
    border: 1px solid $pink400
        }

  .ldlcQU:hover {
    background-color: $purple50
        }

  .jGpmzh:hover {
    border: 1px solid $purple50
        }

  .fxXVZQ[disabled]:hover {
    fill: $purple50
            }

  .beXQNo:hover {
    background-color: $neutral200
        }

  .iObRtl:hover {
    border: 1px solid $neutral200
        }

  .iAaFFC[disabled]:hover {
    border: 1px solid $neutral100
            }

  .iLAjFE[disabled]:hover {
    fill: $neutral200
            }

  .jGEnsY:hover, .jGEnsY:focus,.fJdafl {
    border-color: $purple300
    }

  .ewhlLn:hover, .ewhlLn:focus, .ewhlLn[data-state="active"] {
    border-bottom-color: var(--jRGBpu)
        }

  .kYdGLz:hover > [data-tab-text], .kYdGLz:focus > [data-tab-text], .kYdGLz[data-state="active"] > [data-tab-text] {
    color: var(--jRGBpu)
        }

  .iPxVWT:is(:hover, [data-hover]) {
    color: var(--hGgStN)
        }

  .glyXMS:hover {
    border: 2px solid $neutral200
        }

  .TzKgA:hover {
    border-radius: 14px
        }

  .iydjYN:hover {
    color: $purple400
        }

  .fwTUpU:hover {
    border: none
        }

  .llikpu:hover {
    color: $neutral0
        }

  .PmNZx:hover {
    opacity: 0.8
        }

  .iLhOgD:hover {
    background-color: $pink400
        }

  .hCSbpz[disabled]:hover {
    fill: $pink400
            }

  .kcURTY:hover {
    background-color: $purple500
        }

  .ecfrTG:hover {
    fill: $neutral0
        }

  .iWbsCK[disabled]:hover {
    background-color: $neutral0
            }

  .rFqHY[disabled]:hover {
    fill: $purple500
            }

  .jGEnsY:hover, .jGEnsY:focus {
    border-color: $purple300
        }

  .bSRIfX:hover {
    text-decoration: underline
        }

  .hRRNoX:hover {
    background-color: $neutral100;
    background: $neutral100
        }

  .ggYPPY:hover h4 {
    text-decoration: underline
        }

  .lcGKmJ:hover {
    background-color: var(--hzpwrB)
        }

  .dlSSNH:hover {
    cursor: pointer
        }

  .gSYfzD:hover {
    border-color: var(--iCdJRO)
        }

  .fjaRYn:hover {
    border: 1px solid #b7a0e3
        }

  .ieWvIF:is(:hover, [data-hover]) {
    cursor: pointer
        }

  .dRIiOP:is(:hover, [data-hover]) {
    background: var(--hzpwrB)
        }

  .iGbPQl {
    @md {
      width: 344px
        }
    }

  .lcGMUA {
    @md {
      order: 3
        }
    }

  .jPxtmD {
    @md {
      order: -1
        }
    }

  .biLImW {
    @xs {
      justify-content: space-between
        }
    }

  .hyIXnV {
    @lg {
      justify-content: start
        }
    }

  .biLImW {
    @xs {
      justify-content: space-between
        }
    }

  .hyIXnV {
    @lg {
      justify-content: start
        }
    }

  .jwpoPj {
    @xs {
      gap: 24px
        }
    }

  .cDxLWO {
    @lg {
      gap: 56px
        }
    }

  .biLImW {
    @xs {
      justify-content: space-between
        }
    }

  .hyIXnV {
    @lg {
      justify-content: start
        }
    }

  .biLImW {
    @xs {
      justify-content: space-between
        }
    }

  .hyIXnV {
    @lg {
      justify-content: start
        }
    }

  .jwpoPj {
    @xs {
      gap: 24px
        }
    }

  .cDxLWO {
    @lg {
      gap: 56px
        }
    }

  .ipiyky {
    @lg {
      display: block
        }
    }

  .gogPxJ {
    @lg {
      flex: inherit
        }
    }

  .gogPxJ {
    @lg {
      flex: inherit
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .cLRbwS {
    @initial {
      flex-direction: column-reverse
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .eoyqeU {
    @initial {
      align-items: start
        }
    }

  .dKXTYQ {
    @md {
      align-items: center
        }
    }

  .cLRbwS {
    @initial {
      flex-direction: column-reverse
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .eoyqeU {
    @initial {
      align-items: start
        }
    }

  .dKXTYQ {
    @md {
      align-items: center
        }
    }

  .jcLFRw[data-confirm-resolve-opened=true] {
    @md {
      width: 35%
            }
        }

  .dbYrvu[data-confirm-resolve-opened=false] {
    @md {
      width: 70%
            }
        }

  .cwQxMk {
    @md {
      margin: 0
        }
    }

  .daNzFB {
    @md {
      width: auto
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .eoyqeU {
    @initial {
      align-items: start
        }
    }

  .dKXTYQ {
    @md {
      align-items: center
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .eoyqeU {
    @initial {
      align-items: start
        }
    }

  .dKXTYQ {
    @md {
      align-items: center
        }
    }

  .daNzFB {
    @md {
      width: auto
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .mfsrJ {
    @md {
      flex: 1 1 auto
        }
    }

  .kFRWvh {
    @md {
      grid-template-columns: 1fr repeat(2, auto)
        }
    }

  .eFzYo {
    @md {
      grid-template-areas: "title allButton createButton"
        }
    }

  .bcoqlr {
    @lg {
      padding: 0
        }
    }

  .fFczjR {
    @lg {
      margin: auto
        }
    }

  .ccjubN {
    @xs {
      width: 40%
        }
    }

  .bHUhsm {
    @md {
      width: 343px
        }
    }

  .bHSvXc {
    @lg {
      width: 343px
        }
    }

  .ixzkyU {
    @xs {
      width: 100%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .gPQcTB {
    @lg {
      width: inherit
        }
    }

  .cgmQUD {
    @xs {
      margin-top: 32px
        }
    }

  .fyqrvI {
    @lg {
      margin-top: 64px
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .flqqTX {
    @md {
      max-width: 100%
        }
    }

  .kytwaq {
    @md {
      min-width: auto
        }
    }

  .jIAQwK {
    @md {
      display: flex
        }
    }

  .kbidtv {
    @md {
      display: none
        }
    }

  .jIAQwK {
    @md {
      display: flex
        }
    }

  .ixBXcu {
    @md {
      width: 100%
        }
    }

  .ijkyCe {
    @md {
      position: absolute
        }
    }

  .hzyoXl {
    @md {
      right: 0
        }
    }

  .cqFnMc {
    @md {
      left: initial
        }
    }

  .fyKGgK {
    @md {
      margin: initial
        }
    }

  .ikYyOD {
    @md {
      position: initial
        }
    }

  .jIzfbA {
    @lg {
      display: flex
        }
    }

  .bZfTco {
    @initial {
      direction: column-reverse
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .kbFLIj::-webkit-scrollbar-thumb {
    @lg {
      background: $neutral300
            }
        }

  .ixCjgM {
    @lg {
      width: 100%
        }
    }

  .iDRMFK {
    @lg {
      flex-direction: row
        }
    }

  .dKZiMi {
    @lg {
      align-items: center
        }
    }

  .biQGaa {
    @lg {
      justify-content: space-between
        }
    }

  .fjimGl {
    @lg {
      text-align: left
        }
    }

  .ipiyky {
    @lg {
      display: block
        }
    }

  .bnnEiF {
    @xl {
      font-weight: $regular
        }
    }

  .clvyVv {
    @xl {
      max-width: 25rem
        }
    }

  .bnnEiF {
    @xl {
      font-weight: $regular
        }
    }

  .bnnEiF {
    @xl {
      font-weight: $regular
        }
    }

  .bnnEiF {
    @xl {
      font-weight: $regular
        }
    }

  .dKZMhZ {
    @xl {
      align-items: center
        }
    }

  .jJpxny {
    @xs {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ixzkyU {
    @xs {
      width: 100%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .jgKAYs {
    @lg {
      width: 50%
        }
    }

  .ixzkyU {
    @xs {
      width: 100%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .gPQcTB {
    @lg {
      width: inherit
        }
    }

  .cgmQUD {
    @xs {
      margin-top: 32px
        }
    }

  .fyqrvI {
    @lg {
      margin-top: 64px
        }
    }

  .cFldsJ {
    @md {
      grid-template-columns: repeat(2, 1fr)
        }
    }

  .fciOVS {
    @lg {
      grid-template-columns: repeat(3, 1fr)
        }
    }

  .ixCjgM\! {
    @lg {
      width: 100% !important
        }
    }

  .jJpxny {
    @xs {
      flex-direction: column
        }
    }

  .lbAetc {
    @md {
      width: 65%
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ixzkyU {
    @xs {
      width: 100%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .jgKAYs {
    @lg {
      width: 50%
        }
    }

  .ixzkyU {
    @xs {
      width: 100%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .jgKAYs {
    @lg {
      width: 50%
        }
    }

  .ixzkyU {
    @xs {
      width: 100%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .gPQcTB {
    @lg {
      width: inherit
        }
    }

  .cgmQUD {
    @xs {
      margin-top: 32px
        }
    }

  .fyqrvI {
    @lg {
      margin-top: 64px
        }
    }

  .cFldsJ {
    @md {
      grid-template-columns: repeat(2, 1fr)
        }
    }

  .fciOVS {
    @lg {
      grid-template-columns: repeat(3, 1fr)
        }
    }

  .ixCjgM\! {
    @lg {
      width: 100% !important
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .ixzkyU {
    @xs {
      width: 100%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .gPQcTB {
    @lg {
      width: inherit
        }
    }

  .cgmQUD {
    @xs {
      margin-top: 32px
        }
    }

  .fyqrvI {
    @lg {
      margin-top: 64px
        }
    }

  .kbFLIj::-webkit-scrollbar-thumb {
    @lg {
      background: $neutral300
            }
        }

  .ixCjgM {
    @lg {
      width: 100%
        }
    }

  .iDRMFK {
    @lg {
      flex-direction: row
        }
    }

  .dKZiMi {
    @lg {
      align-items: center
        }
    }

  .biQGaa {
    @lg {
      justify-content: space-between
        }
    }

  .fjimGl {
    @lg {
      text-align: left
        }
    }

  .ipiyky {
    @lg {
      display: block
        }
    }

  .irBXXm {
    @md {
      margin-bottom: 0
        }
    }

  .kRfUWm {
    @md {
      flex: 0 0 1%
        }
    }

  .jUQyul {
    @md {
      gap: 20px
        }
    }

  .kbgRKd {
    @lg {
      display: none
        }
    }

  .kbgRKd {
    @lg {
      display: none
        }
    }

  .dKZiMi {
    @lg {
      align-items: center
        }
    }

  .bUKlLu {
    @lg {
      justify-content: center
        }
    }

  .kBgvEY {
    @lg {
      align-self: center
        }
    }

  .dKZiMi {
    @lg {
      align-items: center
        }
    }

  .iDRMFK {
    @lg {
      flex-direction: row
        }
    }

  .daNlXX {
    @lg {
      width: auto
        }
    }

  .eTmdFi {
    @lg {
      align-self: flex-end
        }
    }

  .jBiIjW {
    @lg {
      margin-top: 0
        }
    }

  .eYxMDa:first-of-type {
    @md {
      border-left-width: 1px
            }
        }

  .bFstdJ:last-of-type {
    @md {
      border-right-width: 1px
            }
        }

  .ioSyNn {
    @md {
      text-align: center
        }
    }

  .hUtnbt {
    @md {
      border-color: #F1F1F1
        }
    }

  .diYpfd {
    @md {
      border-styles: solid
        }
    }

  .krdPdp {
    @md {
      border-bottom-width: 1px
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .hAYFBO > div, .hAYFBO input, .hAYFBO textarea {
    @lg {
      max-width: 100%
            }
        }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDRMFK {
    @lg {
      flex-direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDRMFK {
    @lg {
      flex-direction: row
        }
    }

  .daNlXX {
    @lg {
      width: auto
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDRMFK {
    @lg {
      flex-direction: row
        }
    }

  .jKTlFY {
    @initial {
      flex-wrap: no-wrap
        }
    }

  .cTKjrt {
    @lg {
      flex-wrap: wrap
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDRMFK {
    @lg {
      flex-direction: row
        }
    }

  .jKTlFY {
    @initial {
      flex-wrap: no-wrap
        }
    }

  .cTKjrt {
    @lg {
      flex-wrap: wrap
        }
    }

  .daNzFB {
    @md {
      width: auto
        }
    }

  .diQPSD {
    @md {
      width: 33%
        }
    }

  .bixsOa {
    @md {
      min-width: 250px
        }
    }

  .ixBAVp {
    @sm {
      width: 100%
        }
    }

  .hKPmNf {
    @md {
      width: 48%
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .eoyqeU {
    @initial {
      align-items: start
        }
    }

  .dKXTYQ {
    @md {
      align-items: center
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .eoyqeU {
    @initial {
      align-items: start
        }
    }

  .dKXTYQ {
    @md {
      align-items: center
        }
    }

  .daNzFB {
    @md {
      width: auto
        }
    }

  .cLRbwS {
    @initial {
      flex-direction: column-reverse
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .eoyqeU {
    @initial {
      align-items: start
        }
    }

  .dKXTYQ {
    @md {
      align-items: center
        }
    }

  .cLRbwS {
    @initial {
      flex-direction: column-reverse
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .eoyqeU {
    @initial {
      align-items: start
        }
    }

  .dKXTYQ {
    @md {
      align-items: center
        }
    }

  .daNzFB {
    @md {
      width: auto
        }
    }

  .kytwaq {
    @md {
      min-width: auto
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .bZfTco {
    @initial {
      direction: column-reverse
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .fjjBwb {
    @md {
      text-align: left
        }
    }

  .eaYllj {
    @md {
      width: 80%
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .cSFJmd {
    @lg {
      width: unset
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .cSFJmd {
    @lg {
      width: unset
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .dKZiMi {
    @lg {
      align-items: center
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ixBXcu {
    @md {
      width: 100%
        }
    }

  .jgKAYs {
    @lg {
      width: 50%
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .crxQIt {
    @initial {
      width: 100%
        }
    }

  .eVPLDi {
    @md {
      width: 32%
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .cFldsJ {
    @md {
      grid-template-columns: repeat(2, 1fr)
        }
    }

  .fciOVS {
    @lg {
      grid-template-columns: repeat(3, 1fr)
        }
    }

  .ixCjgM\! {
    @lg {
      width: 100% !important
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .jgKAYs {
    @lg {
      width: 50%
        }
    }

  .jgKAYs {
    @lg {
      width: 50%
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .cFldsJ {
    @md {
      grid-template-columns: repeat(2, 1fr)
        }
    }

  .fciOVS {
    @lg {
      grid-template-columns: repeat(3, 1fr)
        }
    }

  .ixCjgM\! {
    @lg {
      width: 100% !important
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .daNzFB {
    @md {
      width: auto
        }
    }

  .daNzFB {
    @md {
      width: auto
        }
    }

  .bnnEiF {
    @xl {
      font-weight: $regular
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .fgJtmE {
    @xl {
      border-color: #F1F1F1
        }
    }

  .hseuib {
    @xl {
      display: table-cell
        }
    }

  .etGjob {
    @xl {
      display: table-row
        }
    }

  .kmMnie {
    @xl {
      text-align: center
        }
    }

  .jXDLwb {
    @xl {
      padding: inherit
        }
    }

  .kbjWxq {
    @xl {
      display: none
        }
    }

  .ixzkyU {
    @xs {
      width: 100%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .gPQcTB {
    @lg {
      width: inherit
        }
    }

  .cgmQUD {
    @xs {
      margin-top: 32px
        }
    }

  .fyqrvI {
    @lg {
      margin-top: 64px
        }
    }

  .cymUCd {
    @lg {
      grid-template-areas: "welcome welcome" "activities activities" "title button" "cards cards" "cards cards";
        }
    }

  .jBiIjW {
    @lg {
      margin-top: 0
        }
    }

  .dDneqh {
    @lg {
      justify-self: flex-end
        }
    }

  .iDRMFK {
    @lg {
      flex-direction: row
        }
    }

  .dKZiMi {
    @lg {
      align-items: center
        }
    }

  .biQGaa {
    @lg {
      justify-content: space-between
        }
    }

  .iDRMFK {
    @lg {
      flex-direction: row
        }
    }

  .bMKCTY {
    @lg {
      justify-content: flex-end
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .daNzFB {
    @md {
      width: auto
        }
    }

  .daNzFB {
    @md {
      width: auto
        }
    }

  .kbidtv {
    @md {
      display: none
        }
    }

  .jFawMi:first-of-type {
    @md {
      text-align: left
            }
        }

  .drRyKb:first-of-type {
    @md {
      width: 50%
            }
        }

  .jFawOS:first-of-type {
    @md {
      text-align: left
            }
        }

  .cTaqrO {
    @md {
      display: table-cell
        }
    }

  .bEjJpy {
    @md {
      display: table-row
        }
    }

  .jJuIJn {
    @md {
      text-align: center
        }
    }

  .hROgqu {
    @md {
      padding: inherit
        }
    }

  .cTPhex {
    @xs {
      flex-wrap: wrap
        }
    }

  .fxoRwX {
    @md {
      flex-wrap: no-wrap
        }
    }

  .cTPhex {
    @xs {
      flex-wrap: wrap
        }
    }

  .fxoRwX {
    @md {
      flex-wrap: no-wrap
        }
    }

  .gQhmd {
    @xs {
      gap: 8px
        }
    }

  .drmpwR {
    @md {
      gap: 28px
        }
    }

  .kbidtv {
    @md {
      display: none
        }
    }

  .fFdKTj {
    @md {
      margin: auto
        }
    }

  .jgKhoh {
    @sm {
      width: 50%
        }
    }

  .erxhLF {
    @sm {
      float: left
        }
    }

  .ioSyNn {
    @md {
      text-align: center
        }
    }

  .cDzdiZ {
    @md {
      padding-left: 0
        }
    }

  .fFdKTj {
    @md {
      margin: auto
        }
    }

  .ioSyNn {
    @md {
      text-align: center
        }
    }

  .cDzdiZ {
    @md {
      padding-left: 0
        }
    }

  .fFdKTj {
    @md {
      margin: auto
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .ixzkyU {
    @xs {
      width: 100%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .gPQcTB {
    @lg {
      width: inherit
        }
    }

  .cgmQUD {
    @xs {
      margin-top: 32px
        }
    }

  .fyqrvI {
    @lg {
      margin-top: 64px
        }
    }

  .ixzkyU {
    @xs {
      width: 100%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .gPQcTB {
    @lg {
      width: inherit
        }
    }

  .cgmQUD {
    @xs {
      margin-top: 32px
        }
    }

  .fyqrvI {
    @lg {
      margin-top: 64px
        }
    }

  .ixzkyU {
    @xs {
      width: 100%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .gPQcTB {
    @lg {
      width: inherit
        }
    }

  .cgmQUD {
    @xs {
      margin-top: 32px
        }
    }

  .fyqrvI {
    @lg {
      margin-top: 64px
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .cLRbwS {
    @initial {
      flex-direction: column-reverse
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .cLRbwS {
    @initial {
      flex-direction: column-reverse
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ixBXcu {
    @md {
      width: 100%
        }
    }

  .bZfTco {
    @initial {
      direction: column-reverse
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .jKTlFY {
    @initial {
      flex-wrap: no-wrap
        }
    }

  .cTLvaL {
    @md {
      flex-wrap: wrap
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .jKTlFY {
    @initial {
      flex-wrap: no-wrap
        }
    }

  .cTLvaL {
    @md {
      flex-wrap: wrap
        }
    }

  .dpFNSB {
    @md {
      flex: 0 0 60%
        }
    }

  .dJmqwG {
    @md {
      flex: 0 0 50%
        }
    }

  .bfprpr:first-child:nth-last-child(1) {
    @lg {
      flex: 1 calc(100% - 10px)
            }
        }

  .dEWLy:first-child:nth-last-child(1) {
    @lg {
      max-width: calc(100% - 16px)
            }
        }

  .dIhSot:first-child:nth-last-child(2), .dIhSot:first-child:nth-last-child(2) ~ .dIhSot {
    @lg {
      flex: 1 calc(50% - 10px)
            }
        }

  .dYukzg:first-child:nth-last-child(2), .dYukzg:first-child:nth-last-child(2) ~ .dYukzg {
    @lg {
      max-width: calc(50% - 16px)
            }
        }

  .bwitUR:nth-last-child(n+3):nth-child(3n+1), .bwitUR:nth-last-child(n+3):nth-child(3n+1) ~ .bwitUR {
    @lg {
      flex: 1 calc(33.3% - 10px)
            }
        }

  .cUWNtw:nth-last-child(n+3):nth-child(3n+1), .cUWNtw:nth-last-child(n+3):nth-child(3n+1) ~ .cUWNtw {
    @lg {
      max-width: calc(33.3% - 16px)
            }
        }

  .hKPmNf {
    @md {
      width: 48%
        }
    }

  .ixBXcu {
    @md {
      width: 100%
        }
    }

  .ixzkyU {
    @xs {
      width: 100%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .gPQcTB {
    @lg {
      width: inherit
        }
    }

  .cgmQUD {
    @xs {
      margin-top: 32px
        }
    }

  .fyqrvI {
    @lg {
      margin-top: 64px
        }
    }

  .hpGKHQ {
    @md {
      margin-left: 0
        }
    }

  .lbMnGe {
    @md {
      flex-wrap: nowrap
        }
    }

  .fBVULN {
    @md {
      justify-content: none
        }
    }

  .daNzFB {
    @md {
      width: auto
        }
    }

  .mmPCr {
    @md {
      order: 0
        }
    }

  .daNzFB {
    @md {
      width: auto
        }
    }

  .mmPCr {
    @md {
      order: 0
        }
    }

  .lfCNva {
    @md {
      width: 90%
        }
    }

  .mmPCr {
    @md {
      order: 0
        }
    }

  .ipiyky {
    @lg {
      display: block
        }
    }

  .hKNXXp {
    @lg {
      width: 48%
        }
    }

  .lbMnGe {
    @md {
      flex-wrap: nowrap
        }
    }

  .mmPCr {
    @md {
      order: 0
        }
    }

  .irBXXm {
    @md {
      margin-bottom: 0
        }
    }

  .gNbOdJ {
    @md {
      width: 20%
        }
    }

  .mmPCr {
    @md {
      order: 0
        }
    }

  .bMKCTY {
    @lg {
      justify-content: flex-end
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .ioSyNn {
    @md {
      text-align: center
        }
    }

  .cDzdiZ {
    @md {
      padding-left: 0
        }
    }

  .lnaJtv {
    @md {
      width: 320px
        }
    }

  .lnaJtv {
    @md {
      width: 320px
        }
    }

  .lnaJtv {
    @md {
      width: 320px
        }
    }

  .daNzFB {
    @md {
      width: auto
        }
    }

  .kytwaq {
    @md {
      min-width: auto
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .hAYFBO > div, .hAYFBO input, .hAYFBO textarea {
    @lg {
      max-width: 100%
            }
        }

  .bZfTco {
    @initial {
      direction: column-reverse
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECFNM {
    @lg {
      direction: row
        }
    }

  .hAYFBO > div, .hAYFBO input, .hAYFBO textarea {
    @lg {
      max-width: 100%
            }
        }

  .ePACvJ {
    @md {
      width: 160px
        }
    }

  .dkUQKW {
    @md {
      min-width: 160px
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .kQVnXG {
    @md {
      min-width: 120px
        }
    }

  .TDzhL {
    @md {
      min-width: 125px
        }
    }

  .hPGZyx {
    @md {
      grid-template-areas: "header" "tabs" "filters" "hours"
        }
    }

  .jRZUJF {
    @md {
      margin-top: 7rem
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .dKXTYQ {
    @md {
      align-items: center
        }
    }

  .iqBjWt {
    @md {
      box-shadow: none
        }
    }

  .bcoAMx {
    @md {
      padding: 0
        }
    }

  .bIChUN {
    @md {
      grid-template-areas: "action actions" "filters hours"
        }
    }

  .kwBpMM {
    @md {
      justify-content: end
        }
    }

  .coNhdN {
    @md {
      border-bottom-width: 1px
        }
    }

  .hpGARe {
    @md {
      border-color: $neutral200
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .iphkXU {
    @md {
      display: block
        }
    }

  .kFUJTy {
    @md {
      background-color: transparent
        }
    }

  .iphkXU {
    @md {
      display: block
        }
    }

  .iphkXU {
    @md {
      display: block
        }
    }

  .lgnMqR {
    @initial {
      direction: column
        }
    }

  .hECvpe {
    @md {
      direction: row
        }
    }

  .eFTwGI {
    @md {
      margin: 0 0 16px 0
        }
    }

  .cAXnEw {
    @md {
      width: 30%
        }
    }

  .gNbDCD {
    @lg {
      width: 20%
        }
    }

  .fpLscH {
    @xl {
      width: 18%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .eaTvNK {
    @md {
      width: 13rem
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .cAXnEw {
    @md {
      width: 30%
        }
    }

  .gNbDCD {
    @lg {
      width: 20%
        }
    }

  .fpLscH {
    @xl {
      width: 18%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .jgMmtC {
    @md {
      width: 50%
        }
    }

  .eaTvNK {
    @md {
      width: 13rem
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .ifawMb {
    @initial {
      flex-direction: column
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .hAYFBO > div, .hAYFBO input, .hAYFBO textarea {
    @lg {
      max-width: 100%
            }
        }

  .iphkXU {
    @md {
      display: block
        }
    }

  .iphkXU {
    @md {
      display: block
        }
    }

  .fvcWTN {
    @md {
      font-weight: 700
        }
    }

  .dTkHSP {
    @md {
      background-color: $neutral200
        }
    }

  .jVGxbT {
    @md {
      grid-template-areas: "header header" "filters tasks"
        }
    }

  .jRZUJF {
    @md {
      margin-top: 7rem
        }
    }

  .iDQxSs {
    @md {
      flex-direction: row
        }
    }

  .dKXTYQ {
    @md {
      align-items: center
        }
    }

  @media screen and (min-width: 0em) {
    .eCAgRS {
      width: 100%
    }

    .ioEsaX {
      flex-wrap: wrap
    }

    .eqLLpr {
      gap: 8px
    }

    .kzBvDQ {
      id: name
    }

    .gVlzEN {
      -header: Nome
    }

    .hCKRLz {
      id: date
    }

    .dqmpQs {
      -header: Última atualização
    }

    .lfcCRe {
      id: disciplines
    }

    .gzpwDK {
      -header: Disciplina
    }

    .htYelQ {
      justify-content: space-between
    }

    .kJpUdX {
      grid-column: span 4
    }
        }

  @media screen and (min-width: 34em) {
    .juhbnm {
      id: email
    }

    .gMMWCp {
      -header: Email
    }

    .fudEzn {
      id: actions
    }

    .hlmKME {
      -header: Ações
    }

    .ejvOpa {
      id: updatedAt
    }

    .hShZZt {
      -header: Última atualização
    }

    .eCAgOD {
      width: 100%
    }

    .bRCLCs {
      min-width: 260px
    }

    .fazBfy {
      min-height: 140px
    }
        }

  @media screen and (min-width: 48em) {
    .fTWVju[data-audio-animation-recording] + div {
      display: block
        }

    .djeDMD {
      width: 344px
    }

    .fNUekH {
      transform-origin: top right
    }

    .hWfBPC {
      margin-top: 0
    }

    .jScfkv {
      justify-content: space-around
    }

    .dOGtKN {
      width: 320px
    }

    .lppXRw {
      max-height: 44rem
    }

    .dHBKIK {
      height: 19.375rem
    }

    .dRnBKg {
      width: 13rem
    }

    .dRlqjL {
      height: 13rem
    }

    .izuNx {
      width: -webkit-fill-available
    }

    .kPzTgv {
      gap: 28px
    }

    .esrgYg {
      overflow-x: visible
    }

    .cnWzDh {
      overflow: visible
    }

    .PPptR {
      gap: 2rem
    }

    .hzmtur {
      font-size: 14px
    }

    .kxBniI {
      position: absolute
    }

    .diIEdR {
      display: none
    }

    .fUSkHc[data-orientation="vertical"] {
      width: 8px
        }

    .hlIIhr {
      grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .gVpgoT {
      width: 245px
    }

    .gVlxVE {
      height: 245px
    }

    .eXeQIl {
      font-size: 16px
    }

    .cdQsUv {
      line-height: 22px
    }

    .eKrJcJ {
      padding: 28px
    }

    .iuSEEZ {
      right: 30vw
    }

    .gNLBLk {
      margin-bottom: 0px
    }

    .eHXVZg {
      row-gap: 0px
    }

    .fgohqX {
      column-gap: 20px
    }

    .gjtDeY {
      justify-content: flex-end
    }

    .cRBjMe {
      justify-content: center
    }

    .jPzGra {
      gap: 12px
    }

    .hItCbc {
      width: 49%
    }

    .eyiYMm::before {
      height: calc(100% - 6.375rem)
        }

    .ilZjpZ {
      id: status
    }

    .gqzski {
      -header: Status
    }

    .iIxIrk {
      width: 80px
    }

    .iIzoDD {
      height: 80px
    }

    .enElta {
      width: 280px
    }

    .iNVCED {
      width: 68%
    }

    .nstjA {
      align-self: center
    }

    .bJvGwW {
      justify-content: end
    }

    .ePNByS {
      width: 25%
    }

    .dISCms {
      padding-right: 32px
    }

    .dMWMtG {
      padding-left: 32px
    }

    .bRXpsM {
      grid-template-columns: repeat(1, minmax(0, 1fr))
    }

    .iALuCZ {
      width: 500px
    }

    .eplFZh {
      gap: 40px
    }

    .dGmUSp {
      min-height: 450px
    }

    .dpztZk {
      gap: 3rem
    }

    .ipGAhb {
      grid-template-columns: 19rem auto
    }

    .fiBglB {
      grid-template-areas: "header header" "title title" "filters tasks"
    }

    .jrPIjM {
      padding-bottom: 32px
    }

    .BCwSH {
      width: auto
    }

    .hdwUEi svg {
      display: none
        }

    .ghnxMH {
      padding: 1.5rem
    }

    .fLcAKl {
      margin: 1.5rem 0
    }

    .gPeiPq {
      display: block
    }

    .cWHmmv {
      background-color: var(--lbfZji)
    }

    .ipNnIi {
      height: 2.25rem
    }

    .ipMgwN {
      width: 2.25rem
    }

    .ifaLhE {
      padding: 0.25rem
    }

    .jkEeqn {
      font-weight: 700
    }

    .gqdrDV svg {
      display: inline
        }

    .dIreBo {
      background-color: transparent
    }

    .jNoCJw {
      padding: 1rem
    }

    .hWXmzh {
      border-radius: 0.5rem
    }

    .hekKmP {
      gap: 0.75rem
    }

    .etmvup {
      width: 180px
    }

    .loaWIt {
      max-width: 260px
    }

    .bRCLHH {
      min-width: 260px
    }

    .kVsPah {
      padding-inline: 6
    }

    .kXgxLP {
      margin-top: 7rem
    }

    .idvbhi {
      align-items: center
    }

    .dDeVie {
      border-bottom-width: 0px
    }

    .keyKZK {
      min-width: 100px
    }

    .ijxlgA {
      margin: 0 10px
    }

    .fnVhIq {
      max-height: max-content
    }

    .fgwHFw {
      flex-wrap: nowrap
    }

    .fhRkAi {
      border-width: 0px 1px 0px 0px
    }

    .habYnI {
      min-width: 250px
    }

    .htYeae {
      justify-content: space-between
    }

    .WEoRT {
      min-width: max-content
    }

    .hUCnwE {
      margin-right: 0
    }

    .eYWxoo {
      min-width: auto
    }

    .ekQVfl {
      border-radius: var(--jEpJZE)
    }

    .dTQGSo {
      margin-top: 24px
    }

    .dXYFFS {
      margin-bottom: 24px
    }

    .dXinRm {
      width: 30%
    }

    .glFQTx {
      gap: 48px
    }

    .eBZQPQ {
      width: 50%
    }

    .ksOOpF {
      min-width: 280px
    }

    .keEaJG {
      min-height: 130px
    }

    .kLBxpU::after {
      width: 64px
        }

    .enySjb::after {
      height: 64px
        }

    .JKnj {
      padding: 28px 32px
    }

    .dLYSHY {
      min-width: 50rem
    }

    .eZxVSm {
      flex: 2
    }

    .dTHnPl {
      flex: 1 1 0%
    }

    .jKmOPa {
      flex-direction: row
    }

    .eYHzeU {
      padding-inline: 16px
    }

    .clXJBi {
      margin-top: 48px
    }

    .dpfScz {
      width: fit-content
    }

    .bOYYbY {
      background: var(--hzpwrB)
    }
            }

  @media screen and (min-width: 48em) {
    .hvHWIZ:is(:hover, [data-hover]) {
      background: var(--lcYDiE)
        }
            }

  @media screen and (min-width: 48em) {
    .fefwrP:is(:hover, [data-hover]) {
      color: var(--jRGBpu)
        }
            }

  @media screen and (min-width: 48em) {
    .eYWxoo {
      min-width: auto
    }

    .dIreBo {
      background: transparent
    }

    .ekQVfl {
      border-radius: var(--jEpJZE)
    }

    .bYWxBB {
      background: var(--lcYDiE)
    }

    .clXJBi {
      margin-top: 48px
    }

    .jKmOPa {
      flex-direction: row
    }

    .cxImrP {
      gap: 20px
    }

    .hRXDbY {
      margin-bottom: 0
    }

    .dnOJsY {
      flex: 0 0 1%
    }

    .kXgxLP {
      margin-top: 7rem
    }

    .kVsPah {
      padding-inline: 6
    }

    .hzvDJB {
      max-height: 250px
    }

    .jLoTUz {
      width: 250px
    }

    .hkketb {
      max-width: 310px
    }

    .dVHRtM {
      margin-inline: 24px
    }

    .hWmrgV {
      grid-template-columns: repeat(8, 1fr)
    }

    .ewigMR {
      grid-column: span 8
    }

    .cYEsc {
      bottom: -90px
    }

    .jqaUUQ {
      right: 30px
    }

    .fKqELf {
      max-width: 60px
    }

    .dhERlQ {
      max-height: 60px
    }

    .iborOq {
      bottom: -30px
    }

    .erPAyl {
      left: 100px
    }

    .bDdEGV {
      max-width: 55px
    }

    .frqHWG {
      max-height: 55px
    }

    .bcZICs {
      top: 80px
    }

    .dmpJuU {
      right: -20px
    }

    .dUMXEL {
      max-width: 57px
    }

    .bLnDDj {
      max-height: 58px
    }

    .dLwErB {
      margin-bottom: 32px
    }

    .fXiIld {
      max-width: 110px
    }
        }

  @media screen and (min-width: 48em) and (max-width: 60.746875em) {
    .iUSeGo {
      margin: 24px auto
    }
        }

  @media screen and (min-width: 48em) and (max-width: 74.996875em) {
    .dcLwXQ {
      grid-gap: 24px
    }
        }

  @media screen and (min-width: 60.75em) {
    .haqFhk {
      width: 345px
    }

    .jKmOOw {
      flex-direction: row
    }

    .iYFlpz {
      width: 48%
    }

    .kiIWdV {
      padding: 0
    }

    .CNgWT {
      gap: 2.6rem
    }

    .gXHSXl {
      justify-content: flex-start
    }

    .bRTZet {
      flex: 1 1 auto
    }

    .eXeQIP {
      font-size: 16px
    }

    .BIAdW {
      height: auto
    }

    .jSIfvo {
      width: 464px
    }

    .iyxEDG {
      padding: 0 8px
    }

    .fREPdZ {
      min-height: 120px
    }

    .hYycww {
      max-height: 480px
    }

    .cqUdRY {
      grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .ioEsnn {
      flex-wrap: wrap
    }

    .htYdZA {
      justify-content: space-between
    }

    .eCAgKy {
      width: 100%
    }

    .hlIIkx {
      grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .bsktDr {
      justify-content: start
    }

    .gPeiOM {
      display: block
    }

    .gjtDgO {
      justify-content: flex-end
    }

    .fgwHCq {
      flex-wrap: nowrap
    }

    .eBZQMK {
      width: 50%
    }

    .kTqchV {
      padding-inline: 8
    }

    .eXlIZw {
      margin-inline: 40px
    }

    .guCBmo {
      grid-template-columns: repeat(12, 1fr)
    }

    .fzsyks {
      grid-column: span 12
    }
        }

  @media screen and (min-width: 75em) {
    .lfAAXN {
      height: 17.5rem
    }

    .jXWSri {
      z-index: 2
    }

    .ivtshD {
      width: calc(100vw - 5.875rem)
    }

    .egvRYE {
      left: unset
    }

    .jSUUCn {
      z-index: 3
    }

    .kYmACO {
      left: 1.2rem
    }

    .btzcvF {
      right: 1.2rem
    }

    .bLXlqt {
      width: calc(100vw - 7.2rem)
    }

    .gMBvqj {
      display: inline
    }

    .diIDZo {
      display: none
    }

    .emEfgy {
      gap: 93px
    }

    .cjLYRo {
      margin-inline: 74px
    }

    .guCBrT {
      grid-template-columns: repeat(12, 1fr)
    }

    .dbndnW {
      padding: 68.52px 45px 64px 128px
    }

    .knkXLf {
      min-width: 360px
    }
        }
}